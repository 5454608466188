import {authAcquireTokenOrRedirect} from "../auth/authProvider";
import {Translation} from "react-i18next";

const GET = <TResult, >(url, omschrijving, options: RequestInit = {}): Promise<TResult> => {
    return jsonOrTextFetch(url, omschrijving, {
        ...options,
        method: 'GET',
    });
};

const POST = <TResult, >(url, omschrijving, options: RequestInit = {}): Promise<TResult> => {
    return jsonOrTextFetch(url, omschrijving, {
        ...options,
        method: 'POST',
    });
};

const PATCH = <TResult, >(url, omschrijving, options: RequestInit = {}): Promise<TResult> => {
    return jsonOrTextFetch(url, omschrijving, {
        ...options,
        method: 'PATCH',
    });
};

const DELETE = <TResult, >(url, omschrijving, options: RequestInit = {}): Promise<TResult> => {
    return jsonOrTextFetch(url, omschrijving, {
        ...options,
        method: 'DELETE',
    });
};

const jsonOrTextFetch = (url, omschrijving, options: RequestInit = {}) => authenticatedFetch(url, omschrijving, options, response => {
        const contentType = response.headers.get('content-type');
        const contentIsJson = contentType && contentType.indexOf("application/json") !== -1;

        return contentIsJson ? response.json() : response.text();
    }
);

export const authenticatedFetch = async (url, omschrijving, options: any = {}, responseHandler: (response: Response) => any = r => r) => {
    const user = await authAcquireTokenOrRedirect();

    if (!user) {
        return Promise.reject({
            status: 401,
            message: <Translation>{t => t("algemeen:Foutmeldingen.gebruiker-niet-ingelogd", `Gebruiker niet ingelogd`)}</Translation>,
            cause: {}
        });
    }

    const optionsWithBearerToken = {
        ...options,
        headers: {
            ...options.headers,
            'Authorization': `Bearer ${user.accessToken}`,
        }
    };

    return doFetch(url, omschrijving, optionsWithBearerToken, responseHandler);
};

export const doFetch = (url, omschrijving, options: any = {}, responseHandler: (response: Response) => any) => {
    return fetch(url, options)
        .then(response => {
            if (!response.ok) {
                throw response;
            }

            return response;
        })
        .then(responseHandler)
        .catch(err => {
            console.log(err);
            if (err.toString().startsWith('TypeError')) {
                return Promise.reject({
                    status: -1,
                    message: <Translation>
                        {t => t("algemeen:Foutmeldingen.probleem-met-de-backend", "Probleem met backend: {{fout}}", {err})}
                    </Translation>,
                });
            }

            if (err.headers["Content-Type"] !== "application/json") {
                return err;
            }

            return err.json()
                .then(json => {
                    return Promise.reject({
                        message: <Translation>
                            {t => t("algemeen:Foutmeldingen.fout-opgetreden-bij-x",
                            "Fout opgetreden bij {{omschrijving}}.", {omschrijving})}
                        </Translation>,
                        status: err.status,
                        cause: json,
                    });
                });
        });
};

export const unauthenticatedFetch = doFetch;

export {POST, GET, DELETE, PATCH};
