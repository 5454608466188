import {FormikProps} from "formik";

export class FormUtils {

    public static hasErrors<T>(props: FormikProps<T>, namespace: string) {
        return Object.keys(props.errors)
            .some(field => field.startsWith(namespace) && !!props.errors[field]);
    }

    public static isTouched<T>(props: FormikProps<T>, namespace: string) {
        return Object.keys(props.touched)
            .some(field => field.startsWith(namespace) && props.touched[field]);
    }

    public static getValidationClassTab<T>(props: FormikProps<T>, namespace: string) {
        const errorClass = "border-danger";
        const validClass = "border-success";
        const validationTabClass = ["border-bottom", "border-bottom-6"];

        return validationTabClass
            .concat(FormUtils.hasErrors(props, namespace) &&
                    FormUtils.isTouched(props, namespace) ? errorClass : [])
            .concat(!FormUtils.hasErrors(props, namespace) &&
                    FormUtils.isTouched(props, namespace) ? validClass : [])
            .join(" ");
    }

    public static isValid<T>(props: FormikProps<T>, ...fields: string[]) {
        return !fields
            .map((field) => [props.touched[field], props.errors[field]])
            .some(([touched, error]) => touched && error);
    }

    public static parseTextAsList(text: string) {
        if (!text) {
            return [];
        }

        return text
            .split(/\s+/)
            .map((line: string) => line.trim())
            .filter(Boolean);
    }

}
