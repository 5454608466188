import {KlantModel} from '../klant/types';
import {VerzendAdresModel} from '../verzendadres/types';
import {MedewerkerModel} from '../medewerker/types';
import {OperationeelToestelModel} from '../toestel/types';
import {OpdrachtPlanningModel, Skill} from '../planning/types';
import {_Links, TransportKostConfiguratieModel, TransportKostConfiguratieType} from '../types';
import {BestandModel} from '../installatie/types';
import moment from 'moment/moment';
import {ArtikelMetAantalModel} from '../artikel/types';
import {GenereerOpdrachtenProgressTickTypes} from "../opdracht/types";

export enum InterventieStatus {
    IN_ONTWERP = 'IN_ONTWERP',
    GEANNULEERD = 'GEANNULEERD',
    WACHT_OP_KLANT_AKKOORD = 'WACHT_OP_KLANT_AKKOORD',
    KLANT_AKKOORD = 'KLANT_AKKOORD',
    KLANT_NIET_AKKOORD = 'KLANT_NIET_AKKOORD',
    PLANNEN = 'PLANNEN',
    UITGEVOERD = 'UITGEVOERD',
    WACHT_OP_UITVOERING = 'WACHT_OP_UITVOERING',
    NIET_UITGEVOERD = 'NIET_UITGEVOERD',
    UITGESTELD = 'UITGESTELD'
}

export interface InterventieFilters {
    nummer?: number;
    status?: InterventieStatus[];
    statusNotIn?: InterventieStatus[];
    prioriteit?: InterventiePrioriteit[];
    serviceArtikelNr?: string;
    klantNr?: string;
    klantNaam?: string;
    klantDivisie?: string;
    multiToestelFirstOrNull?: boolean;

    isGepland?: boolean;
    geplandeDatum?: Date;
    deadlineTot?: Date;
    skillIn?: string[];

    taakTypeIn?: InterventieTaakType[];
    taakTypeNotIn?: InterventieTaakType[];
}

export enum InterventiePrioriteit {
    SLA = "SLA",
    LEK = "LEK",
    NORMAAL = "NORMAAL",
    LAAG = "LAAG"
}

export enum InterventieTaakType {
    HERSTELLING = "HERSTELLING",
    UPGRADE = "UPGRADE",
    VERPLAATSING = "VERPLAATSING",
    VERHUIS = "VERHUIS",
    OPHALING = "OPHALING",
    STAALNAME_LABO_ONDERZOEK = "STAALNAME_LABO_ONDERZOEK",
    ADVIES = "ADVIES",
    OPSTART = "OPSTART",
    ROLLOUT_RECALL_ACTIE = "ROLLOUT_RECALL_ACTIE"
}

export interface InterventieOverzichtModel {
    id: string;
    nummer: string;
    toestel: OperationeelToestelModel;

    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    status: InterventieStatus;

    creatieTijdstip: string;

    aangemaaktDoor: MedewerkerModel;
    medewerkers: MedewerkerModel[];

    nodigeSkills: string[];
    verwachteDuurtijd: number;
    ticket: string;
    deadline: string;
    prioriteit: InterventiePrioriteit;

    taakType: InterventieTaakType;
    taakOmschrijving: string;

    opdrachtId?: string;
}

export enum ControleWerkingResultaat {
    OKE = "OKE",
    OKE_OPVOLGING_VEREIST = "OKE_OPVOLGING_VEREIST",
    DEFECT = "DEFECT"
}

export interface InterventieModel {
    id: string;
    nummer: string;
    rawNummer: number;
    volgNummer?: number;
    operationeelToestel: OperationeelToestelModel;

    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    status: InterventieStatus;

    creatieTijdstip: string;

    aangemaaktDoor: MedewerkerModel;
    medewerkers: MedewerkerModel[];

    geannuleerdDoor?: MedewerkerModel;
    geannuleerdTijdstip?: string;
    annulatieReden?: string;

    nodigeSkills: Skill[];
    verwachteDuurtijd: number;
    ticket: string;
    deadline: string;
    prioriteit: InterventiePrioriteit;

    taakType: InterventieTaakType;
    taakOmschrijving: string;

    opdrachtId?: string;

    opdrachtPlanning?: OpdrachtPlanningModel;

    klantAkkoordVraag?: string;
    klantAkkoordGevraagdDoor?: MedewerkerModel;
    klantAkkoordGevraagdTijdstip?: string;

    klantAkkoordVerklaring?: string;
    klantAkkoordDoor?: MedewerkerModel;
    klantAkkoordTijdstip?: string;
    interventieOfferte?: BestandModel;

    uitgevoerdDoor?: MedewerkerModel[];
    aankomstTijdstip?: string;
    serviceOrder?: ServiceOrderModel;
    opmerking?: string;
    resultaat?: ControleWerkingResultaat;
    resultaatOpmerking?: string;

    emailAdressen: string[];
    magazijnInstructies?: string;
    garantieProfiel: InterventieGarantieProfiel;

    transportKostConfiguratie: TransportKostConfiguratieModel;

    meeTeGevenArtikels: ArtikelMetAantalModel[];

    bijlage?: BestandModel;
    klantReferentie?: string;

    uitgesteldTotDatum?: string;
}

export const interventieTeLaatGepland = (interventie: InterventieModel): boolean => {
    if (!interventie.opdrachtPlanning || interventie.opdrachtPlanning.entries.length === 0) {
        return false;
    }

    return moment(interventie.opdrachtPlanning.entries[0].geplandeDatum).isAfter(moment(interventie.deadline));
};

export enum InterventieGarantieProfiel {
    HUUR = "HUUR",

    KOOP_GARANTIE_24 = "KOOP_GARANTIE_24",
    KOOP_GARANTIE_EXT27 = "KOOP_GARANTIE_EXT27",
    KOOP_GARANTIE_EXT30 = "KOOP_GARANTIE_EXT30",
    KOOP_GARANTIE_EXT36 = "KOOP_GARANTIE_EXT36",
    KOOP_BUITEN_GARANTIE = "KOOP_BUITEN_GARANTIE",

    GEEN_GARANTIEGEVAL = "GEEN_GARANTIEGEVAL",
    SPECIALE_BEHANDELING_BETALEND = "SPECIALE_BEHANDELING_BETALEND",
    SPECIALE_BEHANDELING_GRATIS = "SPECIALE_BEHANDELING_GRATIS"
}

export interface ServiceOrderModel {
    bedrijfId: string;
    id: string;
    nr: string;
    soort: string;
    _links: _Links;
}

export interface ArtikelMetAantalForm {
    artikelId: string;
    aantal: number;
}

export interface NieuweInterventieForm {
    klantId: string;
    verzendAdresId: string;
    operationeelToestelIds: string[];
    taakType: InterventieTaakType;
    taakOmschrijving: string;
    prioriteit: InterventiePrioriteit;
    meeTeGevenArtikels: ArtikelMetAantalForm[];
    ticket?: string;
    verwachteDuurtijd: number;
    nodigeSkills: Skill[];
    emailAdressen: string[];
    magazijnInstructies: string;
    transportKostType: TransportKostConfiguratieType;
    transportKostValue?: number;
    garantieProfiel?: InterventieGarantieProfiel;
}

export interface NieuweInterventieBulkForm {
    bedrijfId: string;
    serviceArtikelNrs: string[];
    taakType: InterventieTaakType;
    taakOmschrijving: string;
    prioriteit: InterventiePrioriteit;
    meeTeGevenArtikels: ArtikelMetAantalForm[];
    ticket?: string;
    verwachteDuurtijd: number;
    nodigeSkills: Skill[];
    magazijnInstructies: string;
    transportKostType: TransportKostConfiguratieType;
    transportKostValue?: number;
    garantieProfiel?: InterventieGarantieProfiel;
    deadline?: string;
}

export interface BereidInterventiePlanningVoorForm {
    deadline: string;
}

export interface UpdateInterventieGarantieProfielForm {
    garantieProfiel: InterventieGarantieProfiel;
}

export interface UpdateInterventieForm {
    klantReferentie?: string;
    taakOmschrijving?: string;
    garantieProfiel?: InterventieGarantieProfiel;
    meeTeGevenArtikels?: ArtikelMetAantalForm[];
    emailAdressen?: string[];
    magazijnInstructies?: string;
    nodigeSkills?: Skill[];
}

export enum GenericProgressTickTypes {
    EstimatedTimeRemainingTick = "EstimatedTimeRemainingTick",
    ProgressFinishedTick = "ProgressFinishedTick",
    ExcelAangemaaktProgressTick = "ExcelAangemaaktProgressTick"
}

export enum InterventieProgressTickTypes {
    InterventieAanmakenGestartTick = "InterventieAanmakenGestartTick",
    InterventieAanmakenSuccesTick = "InterventieAanmakenSuccesTick",
    InterventieAanmakenGefaaldTick = "InterventieAanmakenGefaaldTick",
    InterventiePlannenGestartTick = "InterventiePlannenGestartTick",
    InterventiePlannenSuccesTick = "InterventiePlannenSuccesTick",
    InterventiePlannenGefaaldTick = "InterventiePlannenGefaaldTick"
}

export interface ProgressTick {
    type: GenericProgressTickTypes | InterventieProgressTickTypes | GenereerOpdrachtenProgressTickTypes | InterventieBulkPlannenProgressTickTypes;
}

export interface InterventieAanmakenGestartTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventieAanmakenGestartTick;
    serviceArtikelNr: string;
}

export interface InterventieAanmakenSuccesTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventieAanmakenSuccesTick;
    interventieId: string;
    serviceArtikelNr: string;
}

export interface InterventieAanmakenGefaaldTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventieAanmakenGefaaldTick;
    serviceArtikelNr: string;
    foutCode: string;
    exceptionMessage: string;
}

export interface InterventiePlannenGestartTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventiePlannenGestartTick;
    serviceArtikelNr: string;
    interventieId: string;
}

export interface InterventiePlannenSuccesTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventiePlannenSuccesTick;
    interventieId: string;
    serviceArtikelNr: string;
}

export interface InterventiePlannenGefaaldTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventiePlannenGefaaldTick;
    interventieId: string;
    serviceArtikelNr: string;
    foutCode: string;
    exceptionMessage: string;
}

export interface EstimatedTimeRemainingTick extends ProgressTick {
    type: GenericProgressTickTypes.EstimatedTimeRemainingTick;
    huidigAantalTicks: number;
    geschatAantalTicks: number;
    resterendeTijdMs: number;
}

export interface ProgressFinishedTick extends ProgressTick {
    type: GenericProgressTickTypes.ProgressFinishedTick;
}

export interface ExcelAangemaaktProgressTick extends ProgressTick {
    type: GenericProgressTickTypes.ExcelAangemaaktProgressTick;
    bestandUrl: string;
}

export interface InterventieBulkPlanningsactiesForm {
    taakType: InterventieTaakType;
    klantNrs: string[];
    bestaandeBezoekAanvraagActie: BestaandeBezoekAanvraagActie;
    actieBijGeenBestaandeBezoekAanvraag: ActieBijGeenBestaandeBezoekAanvraag;

    nieuweBezoekAanvraagGewenstVanDatum?: Date;
    nieuweBezoekAanvraagGewenstTotDatum?: Date;

    interventieUitstellenTotDatum?: Date;
}

export enum BestaandeBezoekAanvraagActie {
    TOEVOEGEN_AAN_BEZOEKAANVRAAG_MET_ONDERHOUD = "TOEVOEGEN_AAN_BEZOEKAANVRAAG_MET_ONDERHOUD",
    TOEVOEGEN_AAN_BEZOEKAANVRAAG_MET_INSTALLATIE = "TOEVOEGEN_AAN_BEZOEKAANVRAAG_MET_INSTALLATIE",
    NOOIT_TOEVOEGEN_AAN_BEZOEKAANVRAAG = "NOOIT_TOEVOEGEN_AAN_BEZOEKAANVRAAG"
}

export enum ActieBijGeenBestaandeBezoekAanvraag {
    MAAK_NIEUWE_BEZOEKAANVRAAG = "MAAK_NIEUWE_BEZOEKAANVRAAG",
    STEL_INTERVENTIE_UIT = "STEL_INTERVENTIE_UIT",
}


export enum InterventieBulkPlannenProgressTickTypes {
    InterventiePlannenNieuweBezoekAanvraagAanmakenGefaaldTick = "InterventiePlannenNieuweBezoekAanvraagAanmakenGefaaldTick",
    InterventiePlannenNieuweBezoekAanvraagAanmakenGestartTick = "InterventiePlannenNieuweBezoekAanvraagAanmakenGestartTick",
    InterventiePlannenNieuweBezoekAanvraagAanmakenSuccesTick = "InterventiePlannenNieuweBezoekAanvraagAanmakenSuccesTick",
    InterventiePlannenOpzoekenGestartTick = "InterventiePlannenOpzoekenGestartTick",
    InterventiePlannenOpzoekenSuccesTick = "InterventiePlannenOpzoekenSuccesTick",
    InterventiePlannenToevoegenAanBezoekAanvraagGefaaldTick = "InterventiePlannenToevoegenAanBezoekAanvraagGefaaldTick",
    InterventiePlannenToevoegenAanBezoekAanvraagGestartTick = "InterventiePlannenToevoegenAanBezoekAanvraagGestartTick",
    InterventiePlannenToevoegenAanBezoekAanvraagSuccesTick = "InterventiePlannenToevoegenAanBezoekAanvraagSuccesTick",
    InterventiePlannenUitstellenGefaaldTick = "InterventiePlannenUitstellenGefaaldTick",
    InterventiePlannenUitstellenGestartTick = "InterventiePlannenUitstellenGestartTick",
    InterventiePlannenUitstellenSuccesTick = "InterventiePlannenUitstellenSuccesTick"
}

export interface InterventiePlannenNieuweBezoekAanvraagAanmakenGefaaldTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenNieuweBezoekAanvraagAanmakenGefaaldTick;
    interventieId: string;
    interventieNummer: string;
    foutCode: string;
    exceptionMessage: string;
}

export interface InterventiePlannenNieuweBezoekAanvraagAanmakenGestartTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenNieuweBezoekAanvraagAanmakenGestartTick;
    interventieId: string;
    interventieNummer: string;
}

export interface InterventiePlannenNieuweBezoekAanvraagAanmakenSuccesTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenNieuweBezoekAanvraagAanmakenSuccesTick;
    interventieId: string;
    interventieNummer: string;
    bezoekAanvraagId: string;
}

export interface InterventiePlannenOpzoekenGestartTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenOpzoekenGestartTick;
    interventieId: string;
    interventieNummer: string;
}

export interface InterventiePlannenOpzoekenSuccesTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenOpzoekenSuccesTick;
    interventieId: string;
    interventieNummer: string;
}

export interface InterventiePlannenToevoegenAanBezoekAanvraagGefaaldTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenToevoegenAanBezoekAanvraagGefaaldTick;
    interventieId: string;
    interventieNummer: string;
    foutCode: string;
    exceptionMessage: string;
}

export interface InterventiePlannenToevoegenAanBezoekAanvraagGestartTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenToevoegenAanBezoekAanvraagGestartTick;
    interventieId: string;
    interventieNummer: string;
}

export interface InterventiePlannenToevoegenAanBezoekAanvraagSuccesTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenToevoegenAanBezoekAanvraagSuccesTick;
    interventieId: string;
    interventieNummer: string;
    bezoekAanvraagId: string;
}

export interface InterventiePlannenUitstellenGefaaldTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenUitstellenGefaaldTick;
    interventieId: string;
    interventieNummer: string;
    foutCode: string;
    exceptionMessage: string;
}

export interface InterventiePlannenUitstellenGestartTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenUitstellenGestartTick;
    interventieId: string;
    interventieNummer: string;
}

export interface InterventiePlannenUitstellenSuccesTick extends ProgressTick {
    type: InterventieBulkPlannenProgressTickTypes.InterventiePlannenUitstellenSuccesTick;
    interventieId: string;
    interventieNummer: string;
}
