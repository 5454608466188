import React, {useEffect, ImgHTMLAttributes} from "react";
import {authenticatedFetch} from "../../helpers/api";
import {blobToDataUri} from "../../helpers/DownloadUtils";

export interface AuthenticatedImageProps extends ImgHTMLAttributes<any> {
    src: string;
    fallbackSrc?: string;
}

export const AuthenticatedImage: React.FC<AuthenticatedImageProps> = (props) => {
    const {src, fallbackSrc, ...rest} = props;

    const [imageSrc, setImageSrc] = React.useState<string | null>(null);

    useEffect(() => {
        async function run() {
            const response = await authenticatedFetch(src, "ophalen afbeelding");
            if (response.ok) {
                const blob = await response.blob();
                const dataUri = await blobToDataUri(blob);
                setImageSrc(dataUri);
            } else {
                setImageSrc(fallbackSrc || null);
            }
        }

        run();
    }, [src, fallbackSrc]);

    if (!imageSrc) {
        return null;
    }

    return (
        <img alt="" {...rest} src={imageSrc}/>
    );
};
