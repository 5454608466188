import {Spinner} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiAlertCircle, mdiCheckCircle, mdiLinkBox, mdiOpenInNew} from "@mdi/js";
import {Translation} from "react-i18next";
import React, {ReactNode} from "react";
import {NavLink} from "react-router-dom";
import {ProgressTick} from "../../../../redux/interventie/types";
import { BulkActieItemRenderer } from "../../../../components/aqualex/BulkActieLijst";
import {
    GenereerOpdrachtenProgressTickTypes,
    OpdrachtenGenererenOpdrachtAanmakenGefaaldTick,
    OpdrachtenGenererenOpdrachtAanmakenGestartTick,
    OpdrachtenGenererenOpdrachtAanmakenSuccesTick, OpdrachtenGenererenServiceOrderOpzoekenGefaaldTick,
    OpdrachtenGenererenServiceOrderOpzoekenGestartTick,
    OpdrachtenGenererenServiceOrderOpzoekenSuccesTick,
    OpdrachtenGenererenServiceOrdersOpzoekenGestartTick,
    OpdrachtenGenererenServiceOrdersOpzoekenSuccesTick,
    OpdrachtenGenererenSyncGestartTick,
    OpdrachtenGenererenSyncSuccesTick
} from "../../../../redux/opdracht/types";

export const genereerOpdrachtenUitServiceOrderNrsItemRenderers: BulkActieItemRenderer[] = [
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenOpdrachtAanmakenGestartTick;
        },
        render(tick: OpdrachtenGenererenOpdrachtAanmakenGestartTick): any {
            return {
                key: `${tick.serviceOrderNr}-${tick.serviceArtikelNr}`,
                icon: <Spinner animation="border" size="sm"/>,
                title: <span>{tick.serviceOrderNr} - {tick.serviceArtikelNr}</span>,
                description: <Translation ns="opdracht">{t => t("Labels.opdracht-aanmaken", "Opdracht aanmaken...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenOpdrachtAanmakenSuccesTick;
        },
        render(tick: OpdrachtenGenererenOpdrachtAanmakenSuccesTick): any {
            return {
                key: `${tick.serviceOrderNr}-${tick.serviceArtikelNr}`,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: <span>{tick.serviceOrderNr} - {tick.serviceArtikelNr}</span>,
                description: <Translation ns="opdracht">{t => <div>{t("Labels.opdracht-aangemaakt", "Opdracht aangemaakt")} <NavLink target="_blank"
                                                                                                      to={`/planning/opdracht/${tick.opdrachtId}`}><Icon size={1 / 1.2} path={mdiOpenInNew}/></NavLink></div>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenOpdrachtAanmakenGefaaldTick;
        },
        render(tick: OpdrachtenGenererenOpdrachtAanmakenGefaaldTick): any {
            return {
                key: `${tick.serviceOrderNr}-${tick.serviceArtikelNr}`,
                icon: <Icon size={1 / 1.5} path={mdiAlertCircle} className="text-danger"/>,
                title: <span>{tick.serviceOrderNr} - {tick.serviceArtikelNr}</span>,
                description: <Translation ns="opdracht">{t => <span>{t("Labels.opdracht-aanmaken-mislukt", "Opdracht aanmaken mislukt")}: {t(tick.foutCode)} {tick.exceptionMessage}</span>}</Translation>,
                isError: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrdersOpzoekenGestartTick;
        },
        render(tick: OpdrachtenGenererenServiceOrdersOpzoekenGestartTick): any {
            return {
                key: "1",
                icon: <Spinner animation="border" size="sm"/>,
                title: "",
                description: <Translation ns="opdracht">{t => t("Labels.service-orders-opzoeken", "Serviceorders opzoeken...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrdersOpzoekenSuccesTick;
        },
        render(tick: OpdrachtenGenererenServiceOrdersOpzoekenSuccesTick): any {
            return {
                key: "1",
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: "",
                description: <Translation ns="opdracht">{t => <span>{t("Labels.service-orders-gevonden", "Serviceorders gevonden")}</span>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrderOpzoekenGestartTick;
        },
        render(tick: OpdrachtenGenererenServiceOrderOpzoekenGestartTick): any {
            return {
                key: tick.serviceOrderNr,
                icon: <Spinner animation="border" size="sm"/>,
                title: tick.serviceOrderNr,
                description: <Translation ns="opdracht">{t => t("Labels.service-order-opzoeken", "Serviceorder opzoeken...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrderOpzoekenSuccesTick;
        },
        render(tick: OpdrachtenGenererenServiceOrderOpzoekenSuccesTick): any {
            return {
                key: tick.serviceOrderNr,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: tick.serviceOrderNr,
                description: <Translation ns="opdracht">{t => <span>{t("Labels.service-order-gevonden", "Serviceorder gevonden")}</span>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrderOpzoekenGefaaldTick;
        },
        render(tick: OpdrachtenGenererenServiceOrderOpzoekenGefaaldTick): any {
            return {
                key: tick.serviceOrderNr,
                icon: <Icon size={1 / 1.5} path={mdiAlertCircle} className="text-danger"/>,
                title: tick.serviceOrderNr,
                description: <Translation ns="opdracht">{t => <span>{t("Labels.service-order-opzoeken-mislukt", "Serviceorder opzoeken mislukt")}: {t(tick.foutCode)} {tick.exceptionMessage}</span>}</Translation>,
                isError: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenSyncGestartTick;
        },
        render(tick: OpdrachtenGenererenSyncGestartTick): any {
            return {
                key: tick.serviceOrderNr,
                icon: <Spinner animation="border" size="sm"/>,
                title: tick.serviceOrderNr,
                description: <Translation ns="opdracht">{t => t("Labels.sync-uitvoeren", "Sync uitvoeren...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenSyncSuccesTick;
        },
        render(tick: OpdrachtenGenererenSyncSuccesTick): any {
            return {
                key: tick.serviceOrderNr,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: tick.serviceOrderNr,
                description: <Translation ns="opdracht">{t => <span>{t("Labels.sync-uitgevoerd", "Sync uitgevoerd")}</span>}</Translation>,
                isError: false
            };
        }
    }
];
