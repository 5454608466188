import {default as React} from "react";
import {RootState} from "../../../../redux/reducers";
import {connect} from 'react-redux';
import {hideModal} from "../../../../redux/ui/actions";
import {Button, Form} from "react-bootstrap";
import PrestatieStatusBadge from "../../../../components/aqualex/opdracht/PrestatieStatusBadge";
import {PrestatieStatus} from "../../../../redux/planning/types";
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {useTranslation} from "../../../../helpers/i18nUtils";
import {Trans} from "react-i18next";

const BehandelServiceOrderMetDynamicsModal = ({
                                                  serviceOrderBedrijfId,
                                                  serviceOrderNr,
                                                  serviceOrderPlatformId,
                                                  onBevestigHandler,
                                                  hideModal,
                                              }) => {

    const onBevestig = ({serviceOrderBedrijfId, serviceOrderPlatformId, deleteRegels}) => {
        hideModal();
        onBevestigHandler(serviceOrderBedrijfId, serviceOrderPlatformId, "true" === deleteRegels);
    }

    const {t} = useTranslation("planning");

    const schema = Yup.object({
        serviceOrderBedrijfId: Yup.string().required(),
        serviceOrderPlatformId: Yup.string().required(),
        deleteRegels: Yup.string().required(t("algemeen:Foutmeldingen.een-keuze-is-verplicht", 'Een keuze is verplicht') as string)
    });

    return (
        <Modal show={true} onHide={hideModal} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("BehandelServiceOrderMetDynamicsModal.behandelServiceOrderMetDynamics",
                    "Behandel serviceorder met Dynamics") as string}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={schema}
                    onSubmit={onBevestig}
                    initialValues={{
                        serviceOrderBedrijfId: serviceOrderBedrijfId,
                        serviceOrderPlatformId: serviceOrderPlatformId,
                        deleteRegels: 'false',
                    }}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      isValid,
                      errors,
                      isSubmitting
                  }) => (
                    <Form noValidate>

                        <ModalBody>
                            <h5>{t("BehandelServiceOrderMetDynamicsModal.hoe-moet-dynamics-aangepast-worden",
                                "Hoe moet Dynamics aangepast worden?")}</h5>
                            <Trans t={t}
                                   i18nKey="BehandelServiceOrderMetDynamicsModal.omschrijving" values={{serviceOrderNr}}>
                                <p>Merk op dat alle opdrachten gelinkt aan
                                    serviceorder <strong>{serviceOrderNr}</strong> worden geïmpacteerd (de bijhorende
                                    prestatie wordt op <PrestatieStatusBadge
                                        status={PrestatieStatus.GEANNULEERD}/> gezet).</p>
                            </Trans>
                            <div className="mb-3">

                                <Form.Check type="radio" id="deleteRegels-check-false" className="mb-2">
                                    <Form.Check.Input type="radio"
                                                      name="deleteRegels"
                                                      value={"false"}
                                                      checked={values.deleteRegels === "false"}
                                                      onChange={handleChange}
                                                      isValid={isSubmitting && !errors.deleteRegels}/>
                                    <Form.Check.Label>
                                        <Trans t={t}
                                               i18nKey="BehandelServiceOrderMetDynamicsModal.omschrijving-herstel-functie">
                                            <strong>Herstel</strong>: zet de relevante serviceorderregels
                                            op <strong>INITIEEL</strong>
                                        </Trans>
                                    </Form.Check.Label>
                                </Form.Check>

                                <Form.Check type="radio" id="deleteRegels-check-true">
                                    <Form.Check.Input type="radio"
                                                      name="deleteRegels"
                                                      value={"true"}
                                                      checked={values.deleteRegels === "true"}
                                                      onChange={handleChange}
                                                      isValid={isSubmitting && !errors.deleteRegels}/>
                                    <Form.Check.Label>
                                        <Trans t={t}
                                               i18nKey="BehandelServiceOrderMetDynamicsModal.omschrijving-verwijder-functie">
                                            <strong>Verwijder</strong>: verwijdert de relevante serviceorderregels<br/>
                                            <span className={"text-muted"}>Opgelet: definitief, kan niet ongedaan gemaakt worden. Enkel regels met herstelstatuscode "AUTO_PLAN" worden verwijderd; indien het serviceorder hierna geen regels bezit wordt ook het serviceorder zelf verwijderd.</span>
                                        </Trans>
                                    </Form.Check.Label>
                                    <Form.Control.Feedback type='invalid'>{errors.deleteRegels}</Form.Control.Feedback>
                                </Form.Check>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light'
                                    onClick={hideModal}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestigen", "Bevestigen")}</Button>
                        </ModalFooter>
                    </Form>)}

            </Formik>


        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    onBevestigHandler: ownProps.onBevestigHandler,
    serviceOrderNr: ownProps.serviceOrderNr,
});

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BehandelServiceOrderMetDynamicsModal);
