import React from 'react';
import {Redirect, Route} from 'react-router-dom';

import {useLoggedInUser} from "../redux/auth/hooks";
import {Translation} from "react-i18next";

// lazy load all the views

// auth
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// bestelbon
const BestelBonOverzichtPage = React.lazy(() => import('../pages/bestelbon/overzicht/BestelBonOverzichtPage'));
const BestelBonDetailPage = React.lazy(() => import('../pages/bestelbon/detail/BestelBonDetailPage'));
const BestelBonOverzichtByOffertePage = React.lazy(() => import('../pages/bestelbon/overzicht-by-offerte/BestelBonOverzichtByOffertePage'));

// Accessoirebestelling
const AccessoireBestellingPage = React.lazy(() => import("../pages/accesoirebestelling/overzicht/AccessoireBestellingOverzichtPage"));
const AccessoireBestellingDetailPage = React.lazy(() => import('../pages/accesoirebestelling/detail/AccessoireBestellingDetailPage'));

// co2bestelling
const Co2BestellingOverzichtPage = React.lazy(() => import('../pages/co2bestelling/overzicht/Co2BestellingOverzichtPage'));
const Co2BestellingDetailPage = React.lazy(() => import('../pages/co2bestelling/detail/Co2BestellingDetailPage'));

// Interventie
const InterventiePage = React.lazy(() => import("../pages/interventie/overzicht/InterventieOverzichtPage"));
const InterventieDetailPage = React.lazy(() => import('../pages/interventie/detail/InterventieDetailPage'));

// Installatie
const InstallatieOverzichtPage = React.lazy(() => import('../pages/installatie/overzicht/InstallatieOverzichtPage'));
const InstallatieDetailPage = React.lazy(() => import('../pages/installatie/detail/InstallatieDetailPage'));
const InstallatieDossierDetailPage = React.lazy(() => import('../pages/installatie/dossierdetail/InstallatieDossierDetailPage'));

// Medewerker
const MedewerkerOverzichtPage = React.lazy(() => import('../pages/medewerker/overzicht/MedewerkerOverzichtPage'));
const MedewerkerDetailPage = React.lazy(() => import('../pages/medewerker/detail/MedewerkerDetailPage'));

// Klant
const KlantOverzichtPage = React.lazy(() => import('../pages/klant/overzicht/KlantOverzichtPage'));
const KlantDetailPage = React.lazy(() => import('../pages/klant/detail/KlantDetailPage'));
const VerzendAdresDetailPage = React.lazy(() => import('../pages/klant/verzendadresdetail/VerzendAdresDetailPage'));

const VerzendAdresOverzichtPage = React.lazy(() => import("../pages/klant/verzendadres/overzicht/VerzendAdresOverzichtPage"));

const KlantAccountOverzichtPage = React.lazy(() => import('../pages/account/overzicht/KlantAccountOverzichtPage'));
const KlantAccountDetailPage = React.lazy(() => import('../pages/account/detail/KlantAccountDetailPage'));

// Toestel
const ToestelDetailPage = React.lazy(() => import('../pages/toestel/detail/ToestelDetailPage'));
const ServiceDetailPage = React.lazy(() => import('../pages/toestel/servicedetail/ServiceDetailPage'));

// Opvolging
const ServiceBezoekOpvolgingOverzichtPage = React.lazy(() => import('../pages/opvolging/overzicht/ServiceBezoekOpvolgingOverzichtPage'))
const ServiceBezoekOpvolgingDetailPage = React.lazy(() => import('../pages/opvolging/detail/ServiceBezoekOpvolgingDetailPage'))

// Planning
const OpdrachtOverzichtPage = React.lazy(() => import('../pages/planning/opdracht/overzicht/OpdrachtOverzichtPage'));
const OpdrachtDetailPage = React.lazy(() => import('../pages/planning/opdracht/detail/OpdrachtDetailPage'));
const BezoekAanvraagDetailPage = React.lazy(() => import('../pages/planning/bezoekaanvraag/detail/BezoekAanvraagDetailPage'));
const BezoekAanvraagOverzichtPage = React.lazy(() => import('../pages/planning/bezoekaanvraag/overzicht/BezoekAanvraagOverzichtPage'));
const BulkActieBezoekAanvraagPage = React.lazy(() => import('../pages/planning/bezoekaanvraag/bulkactie/BulkActieBezoekAanvraagPage'));
const BezoekAanvraagGroepDetailPage = React.lazy(() => import('../pages/planning/bezoekaanvraag/groep/BezoekAanvraagGroepDetailPage'));

const MaandOverzichtPage = React.lazy(() => import('../pages/planning/bezoekaanvraag/maandoverzicht/MaandOverzichtPage'));
const WeekOverzichtPage = React.lazy(() => import('../pages/planning/bezoekaanvraag/weekoverzicht/WeekOverzichtPage'));
const AutomatischePlanningOverzichtPage = React.lazy(() => import('../pages/planning/auto/overzicht/AutomatischePlanningOverzichtPage'));
const AutomatischePlanningDetailPage = React.lazy(() => import('../pages/planning/auto/detail/AutomatischePlanningDetailPage'));
const DagPlanningDetailPage = React.lazy(() => import('../pages/planning/dagplanning/detail/DagPlanningDetailPage'));
const DagPlanningDagOverzichtPage = React.lazy(() => import('../pages/planning/dagplanning/dagoverzicht/DagPlanningDagOverzichtPage'));
const CapaciteitOverzichtPage = React.lazy(() => import('../pages/planning/capaciteit/overzicht/CapaciteitOverzichtPage'));

const MagazijnRapportPage = React.lazy(() => import('../pages/planning/magazijn/MagazijnRapportPage'));

// Varia
const OverPage = React.lazy(() => import('../pages/varia/Over'));
const HomePage = React.lazy(() => import('../pages/varia/HomePage'));

// Sandbox
const SandboxFormPage = React.lazy(() => import('../pages/sandbox/SandboxFormPage'));

// handle auth and authorization

const AuthenticatedRoute = (props) => {
    const {Component, roles, ...rest} = props;

    const user = useLoggedInUser();

    if (!user) {
        // Wordt afgehandeld door MSAL

        return null;
        // return <Redirect to={{pathname: '/account/login', state: {from: props.location}}}/>;
    }

    // check if route is restricted by role
    if (roles && !roles.includes(user.role)) {
        // role not authorised so redirect to home page
        return <Redirect to={{pathname: '/'}}/>;
    }

    // authorised so return component
    return <Component {...rest} />;
};

const PrivateRoute = ({component: Component, roles, ...rest}) => (
    <Route
        {...rest}
        render={props => <AuthenticatedRoute Component={Component} roles={roles} {...props}/>}
    />
);

// bestelbon
const bestelBonMenuRoutes = {
    path: '/bestelbon/',
    exact: true,
    name: <Translation>{t => t("Menu.bestelbon", "Bestelbon")}</Translation>,
    icon: 'dripicons-basket',
    component: BestelBonOverzichtPage,
    route: PrivateRoute,
    header: <Translation>{t => t("Menu.sales", "Sales")}</Translation>,
    requiredAuthorityOneOf: [
        "BESTELBON_ALLE_BESTELBONNEN_ZIEN"
    ]
}
const bestelBonOtherRoute = {
    path: '/bestelbon/:bestelBonId',
    name: 'Bestelbon detail',
    component: BestelBonDetailPage,
    route: PrivateRoute,
    exact: true
}
const bestelBonOfferteRoute = {
    path: '/bestelbon/by-offerte/:offerteId',
    name: 'Bestelbonnen voor offerte',
    component: BestelBonOverzichtByOffertePage,
    route: PrivateRoute
}

// Accessoirebestelling
const accessoireBestellingMenuRoutes = {
    path: '/accessoire-bestelling/',
    exact: true,
    name: <Translation>{t => t("Menu.accessoirebestelling", "Accessoirebestelling")}</Translation>,
    icon: 'mdi mdi-truck-delivery-outline',
    component: AccessoireBestellingPage,
    route: PrivateRoute,
    requiredAuthorityOneOf: ["ACCESSOIREBESTELLINGEN_ZIEN"]
};
const accessoireBestellingOtherRoutes = [
    {
        path: '/accessoire-bestelling/:accessoireBestellingId',
        name: 'Accessoirebestelling detail',
        component: AccessoireBestellingDetailPage,
        route: PrivateRoute,
    },
];

// co2bestelling
const co2BestellingMenuRoutes = {
    path: '/co2bestelling/',
    exact: true,
    name: <Translation>{t => t("Menu.co2-bestelling", "CO₂-bestelling")}</Translation>,
    icon: 'mdi mdi-gas-cylinder',
    component: Co2BestellingOverzichtPage,
    route: PrivateRoute,
    requiredAuthorityOneOf: ["CO2_BESTELLINGEN_ZIEN"]
};
const co2BestellingOtherRoutes = [
    {
        path: '/co2bestelling/:co2BestellingId',
        name: 'CO₂ Bestelling detail',
        component: Co2BestellingDetailPage,
        route: PrivateRoute,
    },
];

// Interventie
const interventieMenuRoutes = {
    path: '/interventie/',
    exact: true,
    name: <Translation>{t => t("Menu.interventie", "Interventie")}</Translation>,
    icon: 'mdi mdi-alarm-light-outline',
    component: InterventiePage,
    route: PrivateRoute,
    requiredAuthorityOneOf: ["INTERVENTIES_ZIEN"]
};
const interventieOtherRoutes = [
    {
        path: '/interventie/:interventieId',
        name: 'Interventiedetail',
        component: InterventieDetailPage,
        route: PrivateRoute,
    },
];

// Installatie
const installatieMenuRoutes = {
    path: '/installatie/',
    exact: true,
    name: <Translation>{t => t("Menu.installatie", "Installatie")}</Translation>,
    icon: 'mdi mdi-water-plus-outline',
    component: InstallatieOverzichtPage,
    route: PrivateRoute,
    header: <Translation>{t => t("Menu.service", "Service")}</Translation>,
    requiredAuthorityOneOf: ["INSTALLATIES_ZIEN"]
}
const installatieOtherRoutes = [
    {
        path: '/installatie/:installatieId',
        name: 'Installatie detail',
        exact: true,
        component: InstallatieDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/installatie/dossier/:dossierId',
        name: 'Installatiedossier detail',
        component: InstallatieDossierDetailPage,
        route: PrivateRoute,
    }
]

// Medewerker
const medewerkerMenuRoutes = {
    path: '/medewerker/',
    exact: true,
    name: <Translation>{t => t("Menu.medewerker", "Medewerker")}</Translation>,
    icon: 'dripicons-user-id',
    component: MedewerkerOverzichtPage,
    route: PrivateRoute,
    requiredAuthorityOneOf: ["MEDEWERKERS_ZIEN"]
}
const medewerkerOtherRoutes = [
    {
        path: '/medewerker/:medewerkerId',
        name: 'Medewerker detail',
        exact: true,
        component: MedewerkerDetailPage,
        route: PrivateRoute,
    },
]

// Klant
const klantMenuRoutes = {
    name: <Translation>{t => t("Menu.klant", "Klant")}</Translation>,
    icon: 'dripicons-user',
    header: <Translation>{t => t("Menu.operations", "Operations")}</Translation>,
    requiredAuthorityOneOf: ["KLANTEN_ZIEN", "VERZENDADRESSEN_ZOEKEN"],
    children: [
        {
            path: "/klant/",
            exact: true,
            name: <Translation>{t => t("Menu.overzicht", "Overzicht")}</Translation>,
            icon: "dripicons-blog",
            component: KlantOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: ["KLANTEN_ZIEN"]
        },
        {
            path: "/klant/verzendadres",
            exact: true,
            name: <Translation>{t => t("Menu.verzendadres", "Verzendadres")}</Translation>,
            icon: "dripicons-location",
            component: VerzendAdresOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: ["VERZENDADRESSEN_ZOEKEN"]
        }
    ]
}
const klantOtherRoutes = [
    {
        path: '/klant/:klantId',
        name: 'Klant detail',
        exact: true,
        component: KlantDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/klant/:klantId/verzendadres/:verzendAdresId',
        name: 'Verzendadres detail',
        exact: true,
        component: VerzendAdresDetailPage,
        route: PrivateRoute,
    },
]

// Klant accounts
const klantAccountMenuRoutes = {
    path: '/klantaccount/',
    exact: true,
    name: <Translation>{t => t("Menu.klantaccount", "Klantaccount")}</Translation>,
    icon: 'dripicons-user-group',
    component: KlantAccountOverzichtPage,
    route: PrivateRoute,
    requiredAuthorityOneOf: ["KLANTACCOUNTS_ZIEN"]
}
const klantAccountOtherRoutes = [
    {
        path: '/klantaccount/:klantAccountId',
        name: 'Klantaccount detail',
        exact: true,
        component: KlantAccountDetailPage,
        route: PrivateRoute
    },
]

const toestelOtherRoutes = [
    {
        path: '/toestel/:toestelId',
        name: 'Toestel detail',
        exact: true,
        component: ToestelDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/service/:serviceId',
        name: 'Service detail',
        exact: true,
        component: ServiceDetailPage,
        route: PrivateRoute,
    },
]

// Opvolging
const opvolgingMenuRoutes = {
    path: '/opvolging/',
    exact: true,
    name: <Translation>{t => t("Menu.opvolging", "Opvolging")}</Translation>,
    icon: 'mdi mdi-timeline-check-outline',
    component: ServiceBezoekOpvolgingOverzichtPage,
    route: PrivateRoute,
    requiredAuthorityOneOf: [
        "OPVOLGINGEN_ZIEN"
    ]
};
const opvolgingOtherRoutes = [
    {
        path: '/opvolging/:opvolgingId',
        name: 'Opvolging detail',
        exact: true,
        component: ServiceBezoekOpvolgingDetailPage,
        route: PrivateRoute,
    },
];

// Planning
const planningMenuRoutes = {
    path: '/planning',
    name: <Translation>{t => t("Menu.planning", "Planning")}</Translation>,
    icon: 'dripicons-calendar',
    header: <Translation>{t => t("Menu.planning-opvolging", "Planning & Opvolging")}</Translation>,
    children: [
        {
            path: '/planning/opdracht',
            exact: true,
            name: <Translation>{t => t("Menu.opdrachten", "Opdrachten")}</Translation>,
            icon: 'dripicons-briefcase',
            component: OpdrachtOverzichtPage,
            route: PrivateRoute
        },
        {
            path: '/planning/bezoek/aanvraag',
            exact: true,
            name: <Translation>{t => t("Menu.bezoekaanvragen", "Bezoekaanvragen")}</Translation>,
            icon: 'mdi mdi-car',
            component: BezoekAanvraagOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: [
                "BEZOEKAANVRAGEN_ZIEN"
            ]
        },
        {
            path: '/planning/auto',
            exact: true,
            name: <Translation>{t => t("Menu.automatische-planning", "Automatische planning")}</Translation>,
            icon: 'dripicons-media-play',
            component: AutomatischePlanningOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: [
                "AUTOMATISCHE_PLANNINGEN_ZIEN"
            ]
        },
        {
            path: '/planning/maandoverzicht/',
            exact: true,
            name: <Translation>{t => t("Menu.maandoverzicht", "Maandoverzicht")}</Translation>,
            icon: 'dripicons-calendar',
            component: MaandOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: [
                "DAGPLANNINGEN_ZIEN"
            ]
        },
        {
            path: '/planning/weekoverzicht/',
            exact: true,
            name: <Translation>{t => t("Menu.weekoverzicht", "Weekoverzicht")}</Translation>,
            icon: 'dripicons-calendar',
            component: WeekOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: [
                "DAGPLANNINGEN_ZIEN"
            ]
        },
        {
            path: '/planning/dagplanning/',
            exact: true,
            name: <Translation>{t => t("Menu.dagoverzicht", "Dagoverzicht")}</Translation>,
            icon: 'dripicons-calendar',
            component: DagPlanningDagOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: [
                "DAGPLANNINGEN_ZIEN"
            ]
        },
        {
            path: '/planning/capaciteit',
            exact: true,
            name: <Translation>{t => t("Menu.capaciteit", "Capaciteit")}</Translation>,
            icon: 'dripicons-graph-bar',
            component: CapaciteitOverzichtPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: [
                "DAGPLANNING_CAPACITEIT_ZIEN"
            ]
        },
        {
            path: '/planning/magazijn',
            exact: true,
            name: <Translation>{t => t("Menu.magazijn", "Magazijn")}</Translation>,
            icon: 'mdi mdi-warehouse',
            component: MagazijnRapportPage,
            route: PrivateRoute,
            requiredAuthorityOneOf: [
                "MAGAZIJN_RAPPORT_GENEREREN"
            ]
        },
    ],
    requiredAuthorityOneOf: [
        "BEZOEKAANVRAGEN_ZIEN",
        "AUTOMATISCHE_PLANNINGEN_ZIEN",
        "DAGPLANNINGEN_ZIEN",
        "MAGAZIJN_RAPPORT_GENEREREN",
        "DAGPLANNING_CAPACITEIT_ZIEN"
    ]
}
const planningOtherRoutes = [
    {
        path: '/planning/opdracht/:opdrachtId',
        name: 'Opdracht detail',
        exact: true,
        component: OpdrachtDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/planning/bezoek/aanvraag/:bezoekAanvraagId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        name: 'Bezoekaanvraag detail',
        exact: true,
        component: BezoekAanvraagDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/planning/bezoek/groep/:bezoekAanvraagGroepId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
        name: 'Bezoekaanvraag groep',
        exact: true,
        component: BezoekAanvraagGroepDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/planning/bezoek/aanvraag/bulk',
        exact: true,
        name: 'Bezoekaanvraag bulk acties',
        icon: 'dripicons-calendar',
        component: BulkActieBezoekAanvraagPage,
        route: PrivateRoute,
    },
    {
        path: '/planning/auto/:automatischePlanningId',
        name: 'Automatische Planning detail',
        exact: true,
        component: AutomatischePlanningDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/planning/dagplanning/:datum([0-9]{4}-[0-9]{2}-[0-9]{2})/:medewerkerId/',
        name: 'Dagplanning detail',
        exact: true,
        component: DagPlanningDetailPage,
        route: PrivateRoute,
    },
    {
        path: '/planning/dagplanning/:datum([0-9]{4}-[0-9]{2}-[0-9]{2})',
        name: 'Dagplanning dagoverzicht',
        exact: true,
        component: DagPlanningDagOverzichtPage,
        route: PrivateRoute,
    },
    {
        path: '/planning/maandoverzicht/:maand([0-9]{4}-[0-9]{2})?',
        name: 'Maandoverzicht',
        exact: true,
        component: MaandOverzichtPage,
        route: PrivateRoute,
    }

]


const variaOtherRoutes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        component: HomePage,
        route: PrivateRoute,
    },
    {
        path: '/over/',
        name: 'Over',
        component: OverPage,
        route: PrivateRoute,
    }
]

const sandboxRoutes = [
    {
        path: '/sandbox/form',
        exact: true,
        name: 'Form Sandbox',
        component: SandboxFormPage,
        route: PrivateRoute,
    },
]


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
    ],
};


// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    bestelBonMenuRoutes,
    bestelBonOtherRoute,
    bestelBonOfferteRoute,
    accessoireBestellingMenuRoutes,
    ...accessoireBestellingOtherRoutes,
    co2BestellingMenuRoutes,
    ...co2BestellingOtherRoutes,
    interventieMenuRoutes,
    ...interventieOtherRoutes,
    installatieMenuRoutes,
    ...installatieOtherRoutes,
    klantMenuRoutes,
    ...klantOtherRoutes,
    klantAccountMenuRoutes,
    ...klantAccountOtherRoutes,
    medewerkerMenuRoutes,
    ...medewerkerOtherRoutes,
    ...toestelOtherRoutes,
    opvolgingMenuRoutes,
    ...opvolgingOtherRoutes,
    planningMenuRoutes,
    ...planningOtherRoutes,
    ...variaOtherRoutes,
    ...sandboxRoutes,
    authRoutes,
];

const authProtectedRoutes = [
    bestelBonMenuRoutes,
    installatieMenuRoutes,
    co2BestellingMenuRoutes,
    accessoireBestellingMenuRoutes,
    interventieMenuRoutes,
    planningMenuRoutes,
    opvolgingMenuRoutes,
    klantMenuRoutes,
    klantAccountMenuRoutes,
    medewerkerMenuRoutes
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export {allRoutes, authProtectedRoutes, allFlattenRoutes};
