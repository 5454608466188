import {
    BereidInterventiePlanningVoorForm, InterventieBulkPlanningsactiesForm,
    InterventieFilters,
    InterventieModel, NieuweInterventieBulkForm,
    NieuweInterventieForm,
    UpdateInterventieForm
} from "./types";
import {baseApi} from "../support/api";
import {
    buildFilteredAndPagedSearchQuery,
    buildGetByIdQuery,
    buildMutation,
    buildUpdateMutation
} from "../support/ApiBuilderUtils";
import {formatDateIso} from "../../helpers/DateUtils";
import {installTranslationKey, useTranslation} from "../../helpers/i18nUtils";
import {useCallback, useState} from "react";
import {authenticatedFetch} from "../../helpers/api";
import Config from "../../helpers/Config";
import {
    InterventieBulkPlanningsactiesFormValues
} from "../../pages/interventie/overzicht/InterventieBulkPlanningsactiesModal";
import {useDispatch} from "react-redux";

export const interventieApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getInterventieById: buildGetByIdQuery<InterventieModel, string>(build,
            installTranslationKey("interventie:APIFoutmeldingen.ophalen-van-interventie", "ophalen van interventie"), "Interventie", id => `/interventie/${id}`),
        searchInterventies: buildFilteredAndPagedSearchQuery<InterventieModel, InterventieFilters>(build,
            installTranslationKey("interventie:APIFoutmeldingen.zoeken-van-interventies", "zoeken van interventies"), "Interventie", () => `/interventie`, filters => ({
                ...filters,
                serviceArtikelNr: filters.serviceArtikelNr || undefined,
                deadlineTot: filters.deadlineTot ? filters.deadlineTot.toISOString() : undefined,
                geplandeDatum: filters.geplandeDatum ? formatDateIso(filters.geplandeDatum) : undefined
            })),
        nieuweInterventie: build.mutation<InterventieModel, NieuweInterventieForm>({
            query: (form) => {
                return {
                    url: `/interventie`,
                    method: "POST",
                    body: form,
                };
            },
            invalidatesTags: (result, error, arg) => [
                {type: "Interventie", id: "PARTIAL-LIST"}
            ],
            extraOptions: {
                omschrijving: "toevoegen van een interventie"
            }
        }),
        annuleerInterventie: buildUpdateMutation<InterventieModel, {
            reden: string
        } & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.annuleren-van-interventie", "annuleren van interventie"), "Interventie", ({id}) => `/interventie/${id}/annuleer`),
        stelInterventieUit: buildUpdateMutation<InterventieModel, {
            uitgesteldTotDatum?: string
        } & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.uitstellen-van-interventie", "uitstellen van interventie"), "Interventie", ({id}) => `/interventie/${id}/uitstellen`, {
                invalidatesTags: [
                    "Opdracht",
                    "Prestatie"
                ]
            }),
        hervatInterventie: buildUpdateMutation<InterventieModel, Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.hervatten-van-interventie", "hervatten van interventie"), "Interventie", ({id}) => `/interventie/${id}/hervatten`),
        vraagKlantToestemmingInterventie: buildUpdateMutation<InterventieModel, {
            vraag: string
        } & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.vraag-toestemming-voor-interventie", "vraag toestemming voor interventie"), "Interventie", ({id}) => `/interventie/${id}/vraagKlantToestemming`),
        klantAkkoordInterventie: buildUpdateMutation<InterventieModel, {
            verklaring: string
        } & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.akkoord-klant-voor-interventie", "akkoord klant voor interventie"), "Interventie", ({id}) => `/interventie/${id}/klantAkkoord`),
        klantNietAkkoordInterventie: buildUpdateMutation<InterventieModel, {
            verklaring: string
        } & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldigen.niet-akkoord-klant-voor-interventie", "niet akkoord klant voor interventie"), "Interventie", ({id}) => `/interventie/${id}/klantNietAkkoord`),
        planInterventie: buildUpdateMutation<InterventieModel, BereidInterventiePlanningVoorForm & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.plannen-van-interventie", "plannen van interventie"), "Interventie", ({id}) => `/interventie/${id}/bereidPlanningVoor`),
        voegInterventieOfferteToe: buildMutation<InterventieModel, {
            bestand: File
        } & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.interventieofferte-opladen", "interventieofferte opladen"),
            "Interventie",
            ({id}) => `/interventie/${id}/offerte`,
            {}, {},
            form => {
                const formData = new FormData();
                Object.keys(form).forEach(key => formData.set(key, form[key]));
                formData.set("bestand", form.bestand, form.bestand.name);

                return formData;
            }
        ),
        verwijderInterventieOfferte: buildUpdateMutation<InterventieModel, Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.interventieofferte-verwijderen", "interventieofferte verwijderen"), "Interventie", ({id}) => `/interventie/${id}/offerte`, undefined, {method: "DELETE"}),

        voegInterventieBijlageToe: buildMutation<InterventieModel, {
            bestand: File
        } & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.interventiebijlage-opladen", "interventiebijlage opladen"),
            "Interventie",
            ({id}) => `/interventie/${id}/bijlage`,
            {}, {},
            form => {
                const formData = new FormData();
                Object.keys(form).forEach(key => formData.set(key, form[key]));
                formData.set("bestand", form.bestand, form.bestand.name);

                return formData;
            }
        ),
        verwijderInterventieBijlage: buildUpdateMutation<InterventieModel, Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.interventieofferte-verwijderen", "interventieofferte verwijderen"), "Interventie", ({id}) => `/interventie/${id}/bijlage`, undefined, {method: "DELETE"}),

        updateInterventie: buildUpdateMutation<InterventieModel, UpdateInterventieForm & Pick<InterventieModel, "id">>(build,
            installTranslationKey("interventie:APIFoutmeldingen.interventie-aanpassen", "interventie aanpassen"), "Interventie", ({id}) => `/interventie/${id}`, {}, {method: "PATCH"})
    })
});

export const useNieuweInterventie = interventieApi.endpoints.nieuweInterventie.useMutation;
export const useGetInterventieById = interventieApi.endpoints.getInterventieById.useQuery;
export const useSearchInterventies = interventieApi.endpoints.searchInterventies.useQuery;
export const useAnnuleerInterventie = interventieApi.endpoints.annuleerInterventie.useMutation;
export const useStelInterventieUit = interventieApi.endpoints.stelInterventieUit.useMutation;
export const useHervatInterventie = interventieApi.endpoints.hervatInterventie.useMutation;
export const useVraagKlantToestemmingInterventie = interventieApi.endpoints.vraagKlantToestemmingInterventie.useMutation;
export const useKlantAkkoordInterventie = interventieApi.endpoints.klantAkkoordInterventie.useMutation;
export const useKlantNietAkkoordInterventie = interventieApi.endpoints.klantNietAkkoordInterventie.useMutation;
export const usePlanInterventie = interventieApi.endpoints.planInterventie.useMutation;
export const useVoegInterventieOfferteToe = interventieApi.endpoints.voegInterventieOfferteToe.useMutation;
export const useVerwijderInterventieOfferte = interventieApi.endpoints.verwijderInterventieOfferte.useMutation;
export const useVoegInterventieBijlageToe = interventieApi.endpoints.voegInterventieBijlageToe.useMutation;
export const useVerwijderInterventieBijlage = interventieApi.endpoints.verwijderInterventieBijlage.useMutation;
export const useUpdateInterventie = interventieApi.endpoints.updateInterventie.useMutation;

export const useNieuweInterventiesInBulk = () => {
    const {t} = useTranslation("interventie");

    const dispatch = useDispatch();

    const [events, setEvents] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const nieuweInterventiesInBulk = useCallback(async (form: NieuweInterventieBulkForm) => {
        setIsLoading(true);

        const result = await authenticatedFetch(Config.BACKOFFICE_BASE_URL + "/api/interventie/bulk", t("APIFoutmeldingen.interventie-in-bulk-aanmaken", "interventie in bulk aanmaken"), {
            method: "POST",
            body: JSON.stringify(form),
            headers: {
                "Content-Type": "application/json"
            }
        });

        const reader = result.body.getReader();

        const processChunk = ({done, value}) => {
            if (done) {
                setIsLoading(false);
                dispatch(baseApi.util.invalidateTags(["Interventie"]));
                return;
            }

            const text = new TextDecoder().decode(value);
            const lines = text.split("\n");

            for (const line of lines) {
                if (!line) {
                    continue;
                }

                const json = JSON.parse(line);

                setEvents((prev) => [...prev, json]);
            }

            reader.read().then(processChunk);
        };
        reader.read().then(processChunk);
    }, []);

    return {
        nieuweInterventiesInBulk,
        ticks: events,
        isLoading
    };
};

export const useInterventieBulkPlannen = () => {
    const {t} = useTranslation("interventie");

    const dispatch = useDispatch();

    const [events, setEvents] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const bulkPlannen = useCallback(async (form: InterventieBulkPlanningsactiesForm) => {
        setIsLoading(true);

        const result = await authenticatedFetch(Config.BACKOFFICE_BASE_URL + "/api/interventie/bulk-plannen", t("APIFoutmeldingen.interventie-in-bulk-aanmaken", "interventie in bulk aanmaken"), {
            method: "POST",
            body: JSON.stringify(form),
            headers: {
                "Content-Type": "application/json"
            }
        });

        const reader = result.body.getReader();

        const processChunk = ({done, value}) => {
            if (done) {
                setIsLoading(false);
                dispatch(baseApi.util.invalidateTags(["Interventie", "Prestatie", "Opdracht"]));

                return;
            }

            const text = new TextDecoder().decode(value);
            const lines = text.split("\n");

            for (const line of lines) {
                if (!line) {
                    continue;
                }

                const json = JSON.parse(line);

                setEvents((prev) => [...prev, json]);
            }

            reader.read().then(processChunk);
        };
        reader.read().then(processChunk);
    }, []);

    return {
        bulkPlannen,
        ticks: events,
        isLoading
    };
};
