import {default as React} from "react";
import {Form} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import {HelpTooltip} from "../HelpTooltip";

export const SwitchFormField: React.FC<FormikFormFieldProps> = ({label, disabled, helpText, ...props}) => {
    return (
        <FormikFormField {...props}>
            {(field, meta) => (
                <>
                    <Form.Switch id={props.id ?? "switch-" + field.name} //zonder deze 'switch-' prefix blijkt het niet te werken
                                 {...field}
                                 label={label}
                                 disabled={disabled}
                                 checked={field.value}
                                 isInvalid={!!meta.error}/>
                    {helpText && <>&nbsp;<HelpTooltip id={`tooltip_help_${field.name}`}>{helpText}</HelpTooltip></>}
                </>
            )}
        </FormikFormField>
    );
};
