import {default as React, useMemo} from "react";
import {Button, Col, Form, FormGroup, ListGroup} from "react-bootstrap";
import {Form as FormikForm, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {useHideModal} from "../../../redux/ui/hooks";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {
    ActieBijGeenBestaandeBezoekAanvraag,
    BestaandeBezoekAanvraagActie,
    InterventieTaakType
} from "../../../redux/interventie/types";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import Config from "../../../helpers/Config";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";
import {DatumFormField} from "../../../components/aqualex/form/DatumFormField";
import {useInterventieBulkPlannen} from "../../../redux/interventie/api";
import {BulkActieLijst} from "../../../components/aqualex/BulkActieLijst";
import {interventieBulkItemRenderers} from "./InterventieBulkItemRenderers";
import {RadioFormField} from "../../../components/aqualex/form/RadioFormField";
import {ReactSelectFormField} from "../../../components/aqualex/form/ReactSelectFormField";
import {FormUtils} from "../../../helpers/FormUtils";
import {interventieBulkPlannenItemRenderers} from "./InterventieBulkPlannenItemRenderers";

export interface InterventieBulkPlanningsactiesModalProps {
    initialValues?: InterventieBulkPlanningsactiesFormValues;
}

export interface InterventieBulkPlanningsactiesFormValues {
    taakType: InterventieTaakType;
    klantNrs: string;
    bestaandeBezoekAanvraagActie: BestaandeBezoekAanvraagActie;
    actieBijGeenBestaandeBezoekAanvraag: ActieBijGeenBestaandeBezoekAanvraag;

    nieuweBezoekAanvraagGewenstVanDatum?: Date;
    nieuweBezoekAanvraagGewenstTotDatum?: Date;

    interventieUitstellenTotDatum?: Date;
}

export const InterventieBulkPlanningsactiesModal: React.FC<InterventieBulkPlanningsactiesModalProps> = (props) => {
    const hideModal = useHideModal();

    const {bulkPlannen, ticks, isLoading: bulkIsLoading} = useInterventieBulkPlannen();

    const onAnnuleer = () => {
        hideModal();
    };

    const onSubmit = async (values: InterventieBulkPlanningsactiesFormValues, helpers: FormikHelpers<any>) => {
        try {
            await bulkPlannen({
                taakType: values.taakType,
                klantNrs: FormUtils.parseTextAsList(values.klantNrs),
                bestaandeBezoekAanvraagActie: values.bestaandeBezoekAanvraagActie,
                actieBijGeenBestaandeBezoekAanvraag: values.actieBijGeenBestaandeBezoekAanvraag,
                nieuweBezoekAanvraagGewenstVanDatum: values.nieuweBezoekAanvraagGewenstVanDatum,
                nieuweBezoekAanvraagGewenstTotDatum: values.nieuweBezoekAanvraagGewenstTotDatum,
                interventieUitstellenTotDatum: values.interventieUitstellenTotDatum
            });
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const {t} = useTranslation("interventie");

    const initialValues = useMemo(() => {
        return {
            ...props.initialValues,
            verwachteDuurtijd: 60,
            transportKostInherit: true,
            garantieProfielAutomatischBepalen: true
        } as InterventieBulkPlanningsactiesFormValues;
    }, [props.initialValues]);

    const validationSchema = Yup.object().shape({
        // uitgesteldTotDatum: Yup.date().nullable().required(t("Foutmeldingen.datum-is-verplicht", "Datum is verplicht") as string),
        taakType: Yup.string().required(t("Foutmeldingen.taaktype-is-verplicht", "Taaktype is verplicht") as string),
        klantNrs: Yup.string().nullable(),
        bestaandeBezoekAanvraagActie: Yup.string().required(t("Foutmeldingen.bestaande-bezoekaanvraag-actie-is-verplicht", "Bestaande bezoekaanvraag actie is verplicht") as string),
        actieBijGeenBestaandeBezoekAanvraag: Yup.string().required(t("Foutmeldingen.actie-bij-geen-bestaande-bezoekaanvraag-is-verplicht", "Actie bij geen bestaande bezoekaanvraag is verplicht") as string),
        nieuweBezoekAanvraagGewenstVanDatum: Yup.date().nullable().when("actieBijGeenBestaandeBezoekAanvraag", {
            is: ActieBijGeenBestaandeBezoekAanvraag.MAAK_NIEUWE_BEZOEKAANVRAAG,
            then: Yup.date().nullable().required(t("Foutmeldingen.gewenst-van-is-verplicht", "Gewenst van is verplicht") as string)
        }),
        nieuweBezoekAanvraagGewenstTotDatum: Yup.date().nullable().when("actieBijGeenBestaandeBezoekAanvraag", {
            is: ActieBijGeenBestaandeBezoekAanvraag.MAAK_NIEUWE_BEZOEKAANVRAAG,
            then: Yup.date().nullable().required(t("Foutmeldingen.gewenst-tot-is-verplicht", "Gewenst tot is verplicht") as string)
        }),
        interventieUitstellenTotDatum: Yup.date().nullable().when("actieBijGeenBestaandeBezoekAanvraag", {
            is: ActieBijGeenBestaandeBezoekAanvraag.STEL_INTERVENTIE_UIT,
            then: Yup.date().nullable().required(t("Foutmeldingen.uitstellen-tot-is-verplicht", "Uitstellen tot is verplicht") as string)
        })
    });

    const bulkBezig = !!ticks.length;

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus size="xl">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.bulk-planningsacties", "Bulk planningsacties")}</ModalTitle>
            </ModalHeader>

            {bulkBezig && (
                <ModalBody>
                    <ListGroup style={{maxHeight: "80vh", overflowY: "auto"}}>
                        <BulkActieLijst ticks={ticks} renderers={interventieBulkPlannenItemRenderers}/>
                    </ListGroup>
                </ModalBody>
            )}
            {!bulkBezig && (<Formik<InterventieBulkPlanningsactiesFormValues> validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
                {(formikProps) => {
                    const {values, isSubmitting} = formikProps;

                    return (
                        <FormikForm noValidate>
                            <ModalBody>
                                <p>
                                    {t("Labels.met-deze-functie-kan-je-in-bulk-planningsacties-uitvoeren-op-interventies", "Met deze functie kan je in bulk planningsacties uitvoeren op interventies")}
                                </p>

                                <h4>{t("Labels.voorwaarden-voor-interventies", "Voorwaarden voor interventies:")}</h4>
                                <p>
                                    {t("Labels.de-interventies-die-je-in-bulk-wil-plannen-moeten-aan-de-volgende-voorwaarden-voldoen", "De interventies die je in bulk wil plannen moeten aan de volgende voorwaarden voldoen:")}
                                    <ul>
                                        <li>{t("Labels.de-interventie-moet-in-status-plannen-zijn", "De interventie moet in de status 'Plannen' zijn")}</li>
                                        <li>{t("Labels.de-interventie-moet-taaktype-hebben", "De interventie moet taaktype hebben: {{taakType}}", {taakType: values.taakType})}</li>
                                        {values.klantNrs?.length ? <li>{t("Labels.de-interventie-moet-klantnummer-hebben-uit-de-lijst", "De interventie moet klantnummer hebben uit de lijst")}</li> : null}
                                    </ul>
                                </p>

                                <h5>{t("Labels.taaktype", "Taaktype")}</h5>
                                <Form.Row>
                                    <FormGroup id="taakType" as={Col}>
                                        <ReactSelectFormField name="taakType" selectProps={{options: Object.keys(InterventieTaakType).map(type => ({
                                            value: type,
                                            label: Config.labels[type]
                                        }))}}/>
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Labels.klantnummers", "Klantnummers")}</h5>
                                <Form.Row>
                                    <FormGroup id="klantNrs" as={Col}>
                                        <TextAreaFormField name="klantNrs" rows={5} placeholder={t("Labels.laat-leeg-voor-geen-filter-op-klant", "Laat leeg voor geen filter op klant")}/>
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Labels.voeg-toe-aan-bestaande-bezoekaanvraag", "Voeg toe aan een bestaande bezoekaanvraag:")}</h5>
                                <Form.Row>
                                    <FormGroup id="bestaandeBezoekAanvraagActie" as={Col}>
                                        <RadioFormField name="bestaandeBezoekAanvraagActie"
                                                          options={[
                                                            {value: BestaandeBezoekAanvraagActie.TOEVOEGEN_AAN_BEZOEKAANVRAAG_MET_ONDERHOUD, label: t("Labels.voeg-toe-aan-een-bestaande-bezoekaanvraag-met-onderhoud", "Voeg toe aan een bestaande bezoekaanvraag met onderhoud")},
                                                            {value: BestaandeBezoekAanvraagActie.TOEVOEGEN_AAN_BEZOEKAANVRAAG_MET_INSTALLATIE, label: t("Labels.voeg-toe-aan-een-bestaande-bezoekaanvraag-met-installatie", "Voeg toe aan een bestaande bezoekaanvraag met installatie")},
                                                            {value: BestaandeBezoekAanvraagActie.NOOIT_TOEVOEGEN_AAN_BEZOEKAANVRAAG, label: t("Labels.nooit-aan-een-bestaande-bezoekaanvraag-toevoegen", "Nooit aan een bestaande bezoekaanvraag toevoegen")}
                                                          ]}/>
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Labels.indien-niet-toegevoegd-aan-een-bestaande-bezoekaanvraag", "Indien niet toegevoegd aan een bestaande bezoekaanvraag:")}</h5>
                                <Form.Row>
                                    <FormGroup id="actieBijGeenBestaandeBezoekAanvraag" as={Col}>
                                        <RadioFormField name="actieBijGeenBestaandeBezoekAanvraag"
                                                          options={[
                                                            {value: ActieBijGeenBestaandeBezoekAanvraag.MAAK_NIEUWE_BEZOEKAANVRAAG, label: t("Labels.maak-een-nieuwe-bezoekaanvraag", "Maak een nieuwe bezoekaanvraag")},
                                                            {value: ActieBijGeenBestaandeBezoekAanvraag.STEL_INTERVENTIE_UIT, label: t("Labels.stel-de-interventie-uit", "Stel de interventie uit")}
                                                          ]}/>
                                    </FormGroup>
                                </Form.Row>

                                {values.actieBijGeenBestaandeBezoekAanvraag === ActieBijGeenBestaandeBezoekAanvraag.MAAK_NIEUWE_BEZOEKAANVRAAG && (
                                    <Form.Row>
                                        <FormGroup id="nieuweBezoekAanvraagGewenstDatum" as={Col}>
                                            <DatumFormField name="nieuweBezoekAanvraagGewenstVanDatum" label={t("Labels.gewenst-van", "Gewenst van")}/>
                                            <DatumFormField name="nieuweBezoekAanvraagGewenstTotDatum" label={t("Labels.gewenst-tot", "Gewenst tot")}/>
                                        </FormGroup>
                                    </Form.Row>
                                )}

                                {values.actieBijGeenBestaandeBezoekAanvraag === ActieBijGeenBestaandeBezoekAanvraag.STEL_INTERVENTIE_UIT && (
                                    <Form.Row>
                                        <FormGroup id="interventieUitstellenTotDatum" as={Col}>
                                            <DatumFormField name="interventieUitstellenTotDatum" label={t("Labels.uitstellen-tot", "Uitstellen tot")}/>
                                        </FormGroup>
                                    </Form.Row>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="light"
                                        onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>
                                <LoadingButton disabled={isSubmitting} loading={isSubmitting}
                                               variant="primary"
                                               type="submit">{t("algemeen:Buttons.uitvoeren", "Uitvoeren")}</LoadingButton>
                            </ModalFooter>
                        </FormikForm>
                    );
                }}
            </Formik>)}
        </Modal>
    );
};
