import {useGetMedewerkersSummary} from "./api";
import {MedewerkerSummaryFilters, MedewerkerSummaryModel} from "./types";

export const useGetMedewerkersSummaryWithFilter = (args: { filters: MedewerkerSummaryFilters }) => {
    const {filters} = args;

    const {data: summaryData, isFetching} = useGetMedewerkersSummary({});

    let data = summaryData
        ?.filter((summary) => {
            if (filters.technieker) {
                return summary.technieker;
            }

            return true;
        });

    return {
        data,
        isFetching
    };
};

export const useGetMedewerkerSummaryById = (id?: string) => {
    const {data: summaryData, isFetching} = useGetMedewerkersSummary({});

    let data: MedewerkerSummaryModel | undefined = undefined;
    if (summaryData) {
        data = summaryData.find((summary) => summary.id === id);
    }

    return {
        data,
        isFetching
    };
};
