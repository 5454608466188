import {
    MedewerkerFilters,
    MedewerkerModel, MedewerkerRol, MedewerkerSummaryModel,
    UpdateMedewerkerForm,
    UpdateMedewerkerPlanningWeekTemplateForm
} from "./types";
import {
    buildApi,
    buildFilteredAndPagedSearchQuery,
    buildGetByIdQuery, buildQuery,
    buildUpdateMutation
} from "../support/ApiBuilderUtils";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const medewerkerApi = buildApi((build) => ({
    getMedewerkerProfielFotoById: buildGetByIdQuery<MedewerkerModel, string>(build,
    installTranslationKey("medewerker:APIFoutmeldingen.ophalen-van-medewerker", "ophalen van medewerker"), "Medewerker", id => `/medewerker/${id}`),
    getMedewerkerById: buildGetByIdQuery<MedewerkerModel, string>(build,
        installTranslationKey("medewerker:APIFoutmeldingen.ophalen-van-medewerker", "ophalen van medewerker"), "Medewerker", id => `/medewerker/${id}`),
    getMedewerkersByIds: build.query({
        queryFn: (ids, _queryApi, _extraOptions, fetchWithBQ) => {
            const promises = ids.map((id) => {
                return fetchWithBQ(`/medewerker/${id}`);
            });

            return Promise.all(promises).then((results) => {
                return {data: results.map(item => item.data)};
            });
        },
    }),
    searchMedewerkers: buildFilteredAndPagedSearchQuery<MedewerkerModel, MedewerkerFilters>(build,
        installTranslationKey("medewerker:APIFoutmeldingen.zoeken-van-medewerkers", "zoeken van medewerkers"), "Medewerker", () => `/medewerker`),
    getMedewerkersSummary: buildQuery<MedewerkerSummaryModel[], {}>(build,
        installTranslationKey("medewerker:APIFoutmeldingen.ophalen-van-medewerkers", "ophalen van medewerkers"), "Medewerker", () => `/refdata/medewerker`),
    updateMedewerker: buildUpdateMutation<MedewerkerModel, UpdateMedewerkerForm & { id: string }>(build,
        installTranslationKey("medewerker:APIFoutmeldingen.toekennen-van-skills-aan-medewerker", "toekennen skills aan medewerker"), "Medewerker", ({id}) => `/medewerker/${id}`, {}, {method: "PATCH"}),
    updateMedewerkerPlatformRollen: buildUpdateMutation<MedewerkerModel, {id: string; platformRollen: MedewerkerRol[]}>(build,
        installTranslationKey("medewerker:APIFoutmeldingen.update-medewerker-platform-rollen", "aanpassen rollen"), "Medewerker", ({id}) => `/medewerker/${id}/platformRollen`, {}, {method: "PATCH"}),
    updateMedewerkerPlanningWeekTemplate: buildUpdateMutation<MedewerkerModel, UpdateMedewerkerPlanningWeekTemplateForm & {
        id: string
    }>(build,
        installTranslationKey("medewerker:APIFoutmeldingen.aanpassen-van-de-planning-weektemplate-van-medewerker", "aanpassen van de planning weektemplate van medewerker"), "Medewerker", ({id}) => `/medewerker/${id}/planningWeekTemplate`, {}, {method: "PATCH"})
}));

export const useGetMedewerkerById = medewerkerApi.endpoints.getMedewerkerById.useQuery;
export const useGetMedewerkersByIds = medewerkerApi.endpoints.getMedewerkersByIds.useQuery;
export const useSearchMedewerkers = medewerkerApi.endpoints.searchMedewerkers.useQuery;
export const useGetMedewerkersSummary = medewerkerApi.endpoints.getMedewerkersSummary.useQuery;
export const useUpdateMedewerker = medewerkerApi.endpoints.updateMedewerker.useMutation;
export const useUpdateMedewerkerPlatformRollen = medewerkerApi.endpoints.updateMedewerkerPlatformRollen.useMutation;
export const useUpdateMedewerkerPlanningWeekTemplate = medewerkerApi.endpoints.updateMedewerkerPlanningWeekTemplate.useMutation;
