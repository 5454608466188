import {Spinner} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiAlertCircle, mdiCheckCircle, mdiOpenInNew} from "@mdi/js";
import {Translation} from "react-i18next";
import React from "react";
import {NavLink} from "react-router-dom";
import {
    InterventieBulkPlannenProgressTickTypes,
    InterventiePlannenNieuweBezoekAanvraagAanmakenGefaaldTick,
    InterventiePlannenNieuweBezoekAanvraagAanmakenGestartTick,
    InterventiePlannenNieuweBezoekAanvraagAanmakenSuccesTick,
    InterventiePlannenOpzoekenGestartTick,
    InterventiePlannenOpzoekenSuccesTick,
    InterventiePlannenToevoegenAanBezoekAanvraagGefaaldTick,
    InterventiePlannenToevoegenAanBezoekAanvraagGestartTick,
    InterventiePlannenToevoegenAanBezoekAanvraagSuccesTick,
    InterventiePlannenUitstellenGefaaldTick,
    InterventiePlannenUitstellenGestartTick,
    InterventiePlannenUitstellenSuccesTick,
    ProgressTick
} from "../../../redux/interventie/types";
import {BulkActieItemRenderer} from "../../../components/aqualex/BulkActieLijst";

export const interventieBulkPlannenItemRenderers: BulkActieItemRenderer[] = [
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenNieuweBezoekAanvraagAanmakenGestartTick;
        },
        render(tick: InterventiePlannenNieuweBezoekAanvraagAanmakenGestartTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Spinner animation="border" size="sm"/>,
                title: tick.interventieNummer,
                description: <Translation
                    ns="interventie">{t => t("Labels.nieuwe-bezoekaanvraag-aanmaken", "Nieuwe bezoekaanvraag aanmaken...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenNieuweBezoekAanvraagAanmakenSuccesTick;
        },
        render(tick: InterventiePlannenNieuweBezoekAanvraagAanmakenSuccesTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: tick.interventieNummer,
                description: <Translation ns="interventie">{t =>
                    <div>{t("Labels.nieuwe-bezoekaanvraag-aangemaakt", "Nieuwe bezoekaanvraag aangemaakt")} <NavLink
                        target="_blank"
                        to={`/planning/bezoek/aanvraag/${tick.bezoekAanvraagId}`}><Icon size={1 / 1.2}
                                                                                        path={mdiOpenInNew}/></NavLink>
                    </div>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenNieuweBezoekAanvraagAanmakenGefaaldTick;
        },
        render(tick: InterventiePlannenNieuweBezoekAanvraagAanmakenGefaaldTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Icon size={1 / 1.5} path={mdiAlertCircle} className="text-danger"/>,
                title: tick.interventieNummer,
                description: <Translation ns="interventie">{t =>
                    <span>{t("Labels.nieuwe-bezoekaanvraag-aanmaken-mislukt", "Nieuwe bezoekaanvraag aanmaken mislukt")}: {t(tick.foutCode)} {tick.exceptionMessage}</span>}</Translation>,
                isError: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenToevoegenAanBezoekAanvraagGestartTick;
        },
        render(tick: InterventiePlannenToevoegenAanBezoekAanvraagGestartTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Spinner animation="border" size="sm"/>,
                title: tick.interventieNummer,
                description: <Translation
                    ns="interventie">{t => t("Labels.toevoegen-aan-bezoekaanvraag", "Toevoegen aan bezoekaanvraag...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenToevoegenAanBezoekAanvraagSuccesTick;
        },
        render(tick: InterventiePlannenToevoegenAanBezoekAanvraagSuccesTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: tick.interventieNummer,
                description: <Translation ns="interventie">{t =>
                    <div>{t("Labels.toegevoegd-aan-bezoekaanvraag", "Toegevoegd aan bezoekaanvraag")} <NavLink
                        target="_blank"
                        to={`/planning/bezoek/aanvraag/${tick.bezoekAanvraagId}`}><Icon size={1 / 1.2}
                                                                                        path={mdiOpenInNew}/></NavLink>
                    </div>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenToevoegenAanBezoekAanvraagGefaaldTick;
        },
        render(tick: InterventiePlannenToevoegenAanBezoekAanvraagGefaaldTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Icon size={1 / 1.5} path={mdiAlertCircle} className="text-danger"/>,
                title: tick.interventieNummer,
                description: <Translation ns="interventie">{t =>
                    <span>{t("Labels.toevoegen-aan-bezoekaanvraag-mislukt", "Toevoegen aan bezoekaanvraag mislukt")}: {t(tick.foutCode)} {tick.exceptionMessage}</span>}</Translation>,
                isError: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenUitstellenGestartTick;
        },
        render(tick: InterventiePlannenUitstellenGestartTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Spinner animation="border" size="sm"/>,
                title: tick.interventieNummer,
                description: <Translation
                    ns="interventie">{t => t("Labels.interventie-uitstellen", "Interventie uitstellen...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenUitstellenSuccesTick;
        },
        render(tick: InterventiePlannenUitstellenSuccesTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: tick.interventieNummer,
                description: <Translation ns="interventie">{t =>
                    <div>{t("Labels.interventie-uitgesteld", "Interventie uitgesteld")}</div>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenUitstellenGefaaldTick;
        },
        render(tick: InterventiePlannenUitstellenGefaaldTick): any {
            return {
                key: tick.interventieNummer,
                icon: <Icon size={1 / 1.5} path={mdiAlertCircle} className="text-danger"/>,
                title: tick.interventieNummer,
                description: <Translation ns="interventie">{t =>
                    <span>{t("Labels.interventie-uitstellen-mislukt", "Interventie uitstellen mislukt")}: {t(tick.foutCode)} {tick.exceptionMessage}</span>}</Translation>,
                isError: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenOpzoekenGestartTick;
        },
        render(tick: InterventiePlannenOpzoekenGestartTick): any {
            return {
                key: "1",
                icon: <Spinner animation="border" size="sm"/>,
                title: "",
                description: <Translation
                    ns="interventie">{t => t("Labels.interventies-opzoeken", "Interventies opzoeken...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieBulkPlannenProgressTickTypes.InterventiePlannenOpzoekenSuccesTick;
        },
        render(tick: InterventiePlannenOpzoekenSuccesTick): any {
            return {
                key: "1",
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: "",
                description: <Translation ns="interventie">{t =>
                    <span>{t("Labels.interventies-gevonden", "Interventies gevonden")}</span>}</Translation>,
                isError: false
            };
        }
    },
];
