import React from "react";
import {Assets} from "../../assets/images";
import {AuthenticatedImage} from "./AuthenticatedImage";

export interface ProfielfotoAvatarProps {
    src?: string;
    size: "xs" | "sm" | "md" | "lg" | "xl";

    rounded?: boolean;
    className?: string;
}

export const ProfielfotoAvatar: React.FC<ProfielfotoAvatarProps> = (props) => {
    const {src, size, rounded, className} = props;

    return (
        <AuthenticatedImage src={src || ""} fallbackSrc={Assets.user} alt="Profielfoto" className={`avatar-${size} ${rounded ? "rounded-circle" : ""} ${className || ""}`}/>
    );
};
