import {OpdrachtModel, PrestatieModel} from "../planning/types";
import {buildApi, buildGetByIdQuery, buildMutation, buildQuery} from "../support/ApiBuilderUtils";
import {installTranslationKey, useTranslation} from "../../helpers/i18nUtils";
import {useCallback, useState} from "react";
import {authenticatedFetch} from "../../helpers/api";
import Config from "../../helpers/Config";
import {GenereerOpdrachtenUitOnderhoudenForm, GenereerOpdrachtenUitServiceOrderNrsForm} from "./types";
import {baseApi} from "../support/api";
import {useDispatch} from "react-redux";

export const opdrachtApi = buildApi(build => ({
    getOpdrachtById: buildGetByIdQuery<OpdrachtModel, string>(build,
        installTranslationKey("opdracht:APIFoutmeldingen.ophalen-van-opdracht", "ophalen van opdracht"), "Opdracht", id => `/opdracht/${id}`),
    getPrestatiesByOpdrachtId: buildQuery<PrestatieModel[], { opdrachtId: string }>(build,
        installTranslationKey("opdracht:APIFoutmeldingen.ophalen-van-prestaties-van-opdrachten", "ophalen van prestaties van opdracht"), "Opdracht", ({opdrachtId}) => `/opdracht/${opdrachtId}/prestaties`, {
            providesTags: (result, error, {opdrachtId}) => result
                ? [
                    ...result.map(({id}) => ({type: "Prestatie" as const, id})),
                    {type: "Prestatie" as const, id: `OPDRACHT-${opdrachtId}`},
                ]
                : [{type: "Prestatie" as const, id: `OPDRACHT-${opdrachtId}`}],
        }),
    getPrestatiesByOpdrachtIds: build.query<Record<string, PrestatieModel[]>, string[]>({
        queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
            const prestatieMap: Record<string, PrestatieModel[]> = {};

            const results = await Promise.all(
                _arg.map(opdrachtId => fetchWithBQ(`/opdracht/${opdrachtId}/prestaties`))
            );

            for (let i = 0; i < results.length; i++) {
                const opdrachtId = _arg[i];
                const prestaties = results[i].data as PrestatieModel[];

                prestatieMap[opdrachtId] = prestaties;
            }

            return {
                data: prestatieMap
            };
        },
        providesTags: (result, error, arg) => {
            return Object.values(result || {}).flatMap(prestatie => prestatie.map(item => ({
                type: "Prestatie",
                id: item.id
            })));
        },
        extraOptions: {
            omschrijving: installTranslationKey("opdracht:APIFoutmeldingen.ophalen-van-prestaties", "ophalen van prestaties"),
        }
    }),
    behandelServiceOrderMetDynamics: buildMutation<{}, {
        serviceOrderBedrijfId: string;
        serviceOrderPlatformId: string;
        deleteRegels: boolean;
        opdrachtId?: string
    }>(build,
        installTranslationKey("interventie:APIFoutmeldingen.behandel-serviceorder-met-dynamics", "Behandel serviceorder met Dynamics"),
        "Opdracht",
        () => `/opdracht/behandelServiceOrderOnderhoudMetDynamicsPrestatiesCommand`,
        {
            invalidatesTags: (result, error, arg, meta) => [{type: "Opdracht", id: arg.opdrachtId}, {
                type: "Prestatie",
                id: `OPDRACHT-${arg.opdrachtId}`
            }]
        }
    ),
    herbezoekOnderhoudServiceOrder: buildMutation<{}, {
        serviceOrderBedrijfId: string;
        serviceOrderPlatformId: string;
        opmerking: string;
        opdrachtId?: string
    }>(build,
        installTranslationKey("interventie:APIFoutmeldingen.herbezoek-onderhoud-serviceorder", "Herbezoek onderhoud serviceorder"),
        "Opdracht",
        () => `/opdracht/herbezoekOnderhoudServiceOrderCommand`,
        {
            invalidatesTags: (result, error, arg, meta) => [{type: "Opdracht", id: arg.opdrachtId}, {
                type: "Prestatie",
                id: `OPDRACHT-${arg.opdrachtId}`
            }]
        }
    )
}));

export const useGetOpdrachtById = opdrachtApi.endpoints.getOpdrachtById.useQuery;
export const useGetPrestatiesByOpdrachtId = opdrachtApi.endpoints.getPrestatiesByOpdrachtId.useQuery;
export const useGetPrestatiesByOpdrachtIds = opdrachtApi.endpoints.getPrestatiesByOpdrachtIds.useQuery;
export const useBehandelServiceOrderMetDynamics = opdrachtApi.endpoints.behandelServiceOrderMetDynamics.useMutation;
export const useHerbezoekOnderhoudServiceOrder = opdrachtApi.endpoints.herbezoekOnderhoudServiceOrder.useMutation;

export const useGenereerOpdrachtenUitServiceOrderNrs = () => {
    const {t} = useTranslation("opdracht");

    const dispatch = useDispatch();

    const [ticks, setTicks] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const genereerOpdrachten = useCallback(async (form: GenereerOpdrachtenUitServiceOrderNrsForm) => {
        setIsLoading(true);

        const result = await authenticatedFetch(Config.BACKOFFICE_BASE_URL + "/api/opdracht/generateServiceOrderOpdrachtenFromServiceOrderNrs", t("APIFoutmeldingen.opdrachten-genereren-uit-service-order-nrs", "opdrachten genereren uit serviceordernummers"), {
            method: "POST",
            body: JSON.stringify(form),
            headers: {
                "Content-Type": "application/json"
            }
        });

        const reader = result.body.getReader();

        const processChunk = ({done, value}) => {
            if (done) {
                setIsLoading(false);
                dispatch(baseApi.util.invalidateTags(["Prestatie", "Opdracht"]));
                return;
            }

            const text = new TextDecoder().decode(value);
            const lines = text.split("\n");

            for (const line of lines) {
                if (!line) {
                    continue;
                }

                const json = JSON.parse(line);

                setTicks((prev) => [...prev, json]);
            }

            reader.read().then(processChunk);
        };
        reader.read().then(processChunk);
    }, []);

    return {
        genereerOpdrachten,
        ticks,
        isLoading
    };
};

export const useGenereerOpdrachtenUitOnderhouden = () => {
    const {t} = useTranslation("opdracht");

    const [ticks, setTicks] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const genereerOpdrachten = useCallback(async (form: GenereerOpdrachtenUitOnderhoudenForm) => {
        setIsLoading(true);

        const result = await authenticatedFetch(Config.BACKOFFICE_BASE_URL + "/api/opdracht/generateOpdrachtenForOnderhoudenInitieelServiceOrdersCommand", t("APIFoutmeldingen.opdrachten-genereren-uit-service-order-nrs", "opdrachten genereren uit serviceordernummers"), {
            method: "POST",
            body: JSON.stringify(form),
            headers: {
                "Content-Type": "application/json"
            }
        });

        const reader = result.body.getReader();

        const processChunk = ({done, value}) => {
            if (done) {
                setIsLoading(false);
                return;
            }

            const text = new TextDecoder().decode(value);
            const lines = text.split("\n");

            for (const line of lines) {
                if (!line) {
                    continue;
                }

                const json = JSON.parse(line);

                setTicks((prev) => [...prev, json]);
            }

            reader.read().then(processChunk);
        };
        reader.read().then(processChunk);
    }, []);

    return {
        genereerOpdrachten,
        ticks,
        isLoading
    };
};
