import {VerzendAdresModel} from "../verzendadres/types";
import {BestandModel} from "../installatie/types";
import {KlantModel} from "../klant/types";
import {ArtikelModel} from "../toestel/types";
import {MedewerkerModel} from "../medewerker/types";
import {Co2BestellingModel} from "../co2bestelling/types";
import {Co2LeveringOpdrachtModel, OpdrachtType, PrestatieModel,} from "../planning/types";
import {ServiceRapportModel} from "../servicerapport/types";
import {_Links, TransportKostConfiguratieType, TransportKostOnderhevig} from "../types";
import {ServiceContractRefModel} from "../service/types";
import {InterventieTaakType} from "../interventie/types";

export interface ServiceBezoekOpvolgingFilters {
    vanDatum?: Date;
    totDatum?: Date;
    status?: string;
    klantNr?: string;
    klantDivisie?: string;
    klantNaam?: string;
    opvolgingItemTypeInFoutOfOpvolgingNodig?: string[];
    nietUitgevoerdWerkOpdrachtType?: OpdrachtType;
    niveau?: ServiceBezoekOpvolgingNiveau;
    interventieTaakTypes?: InterventieTaakType[];
}

export enum ServiceBezoekOpvolgingStatus {
    NIEUW = "NIEUW",
    AUTOMATISCH_VERWERKEN = "AUTOMATISCH_VERWERKEN",
    OPVOLGING_NODIG = "OPVOLGING_NODIG",
    TERUG_BIJ_TECHNICUS = "TERUG_BIJ_TECHNICUS",
    GEARCHIVEERD = "GEARCHIVEERD",
    FOUT = "FOUT",
}

export interface ServiceBezoekOpvolgingRefModel {
    id: string;
    nummer: string;
}

export interface ServiceBezoekOpvolgingNavigationModel {
    previous: ServiceBezoekOpvolgingRefModel;
    current: ServiceBezoekOpvolgingRefModel;
    next: ServiceBezoekOpvolgingRefModel;
}

export interface ServiceBezoekOpvolgingOverzichtItemModel {
    status: OpvolgingItemStatus;
    type: OpvolgingItemDefinitieType;
}

export enum ServiceBezoekOpvolgingNiveau {
    INSTALLATIE = "INSTALLATIE",
    INTERVENTIE = "INTERVENTIE",
    ONDERHOUD = "ONDERHOUD"
}

export interface ServiceBezoekOpvolgingOverzichtModel {
    id: string;
    nummer: string;
    datum: string;
    gestartTijdstip?: string;
    nietBezochtReden?: NietBezochtRedenType;
    nietBezochtRedenCategorie?: NietBezochtRedenTypeCategorie;
    // klant: KlantModel;
    klantId: string;
    // verzendAdres: VerzendAdresModel;
    verzendAdresId: string;
    status: ServiceBezoekOpvolgingStatus;
    // medewerkers: MedewerkerModel[];
    medewerkers: string[];
    items: ServiceBezoekOpvolgingOverzichtItemModel[];
    niveau: ServiceBezoekOpvolgingNiveau;
}

export interface ServiceBezoekOpvolgingOndertekeningModel {
    naam: string;
    emailAdressen: string[];
    handtekening: string;
    tijdstip: string;
}

export interface TechnicusVanTotTijdstip {
    medewerker: MedewerkerModel;
    vanTijd: string;
    totTijd: string;
}

export interface ServiceBezoekOpvolgingModel {
    id: string;
    nummer: number;
    creatieTijdstip: string;
    datum: string;
    gestartTijdstip?: string;
    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    status: ServiceBezoekOpvolgingStatus;
    items: OpvolgingItemModel<any>[];
    aanwezigheden: TechnicusVanTotTijdstip[];
    ondertekening: ServiceBezoekOpvolgingOndertekeningModel;

    nietBezochtDoor?: MedewerkerModel;
    nietBezochtReden?: NietBezochtRedenType;
    nietBezochtRedenCategorie?: NietBezochtRedenTypeCategorie;
    nietBezochtToelichting?: string;

    navigation: ServiceBezoekOpvolgingNavigationModel;
    niveau: ServiceBezoekOpvolgingNiveau;
}

// TODO move to helpers
export const getMedewerkersInOpvolging = (opvolging: {
    nietBezochtDoor?: MedewerkerModel;
    aanwezigheden: TechnicusVanTotTijdstip[]
}): MedewerkerModel[] => {
    const {nietBezochtDoor, aanwezigheden} = opvolging;

    const medewerkerIds = new Set();

    const medewerkers = [] as MedewerkerModel[];

    aanwezigheden.forEach(item => {
        if (!medewerkerIds.has(item.medewerker.id)) {
            medewerkers.push(item.medewerker);
            medewerkerIds.add(item.medewerker.id);
        }
    });

    if (nietBezochtDoor) {
        if (!medewerkerIds.has(nietBezochtDoor.id)) {
            medewerkers.push(nietBezochtDoor);
        }
    }

    return medewerkers;
}

export interface OpvolgingItemModel<T extends OpvolgingItemDefinitieModel> {
    id: string;
    huidigeVersie: OpvolgingItemVersieModel<T>;
    versies: OpvolgingItemVersieModel<T>[];
    _qualifiedId: string;
}

export enum OpvolgingItemStatus {
    TE_VERWERKEN = "TE_VERWERKEN",
    OPVOLGING_NODIG = "OPVOLGING_NODIG",
    VERWERKT = "VERWERKT",
    FOUT = "FOUT",
    GEANNULEERD = "GEANNULEERD",
}

export interface OpvolgingItemGeschienisItemModel {
    tijdstip: string;
    medewerker?: MedewerkerModel;
    status: OpvolgingItemStatus;
    toelichting?: string;
}

export interface OpvolgingItemVersieModel<T extends OpvolgingItemDefinitieModel> {
    tijdstip: string;
    medewerker?: MedewerkerModel;
    status: OpvolgingItemStatus;
    toelichting?: string;
    definitie: T;
}

export enum OpvolgingItemDefinitieType {
    WATERMETER_STAND_OPNAME = "WATERMETER_STAND_OPNAME",
    CO2_LEVERING = "CO2_LEVERING",
    MAAK_EN_VERSTUUR_SERVICE_RAPPORT = "MAAK_EN_VERSTUUR_SERVICE_RAPPORT",
    KLANT_COMMUNICATIE = "KLANT_COMMUNICATIE",
    INTERNE_FEEDBACK = "INTERNE_FEEDBACK",
    KLANT_EMAIL_ADRES_AANPASSING = "KLANT_EMAIL_ADRES_AANPASSING",
    TOESTEL_INFORMATIE_AANPASSING = "TOESTEL_INFORMATIE_AANPASSING",
    TOESTEL_STUKLIJST_AANPASSING = "TOESTEL_STUKLIJST_AANPASSING",
    KLANT_OPMERKING = "KLANT_OPMERKING",
    TOESTEL_BIJLAGEN = "TOESTEL_BIJLAGEN",
    TOESTEL_ONDERHOUD = "TOESTEL_ONDERHOUD",
    TOESTEL_INTERVENTIE = "TOESTEL_INTERVENTIE",
    ACCESSOIRE_LEVERING = "ACCESSOIRE_LEVERING",
    INSTALLATIE = "INSTALLATIE",
    NIET_UITGEVOERD_WERK = "NIET_UITGEVOERD_WERK",
    UPDATE_PLANNING = "UPDATE_PLANNING",
    DYNAMICS_ORDERS_OPVOLGING = "DYNAMICS_ORDERS_OPVOLGING",
    DYNAMICS_SERVICECONTRACT_OPVOLGING = "DYNAMICS_SERVICECONTRACT_OPVOLGING",
    TRANSPORT_KOST_OPVOLGING = "TRANSPORT_KOST_OPVOLGING",
    TOESTEL_SERVICE_LOG_BOEK_AANVULLEN = "TOESTEL_SERVICE_LOG_BOEK_AANVULLEN"
}

export interface OpvolgingItemDefinitieModel {
    type: OpvolgingItemDefinitieType;
}

export interface WatermeterStandOpnameModel extends OpvolgingItemDefinitieModel {
    operationeelToestelId: string;
    medewerker: MedewerkerModel;
    tijdstip: string;
    meterstand: string;
}

export interface MaakEnVerstuurServiceRapportModel extends OpvolgingItemDefinitieModel {
    emailAdressen: string[];
    // resultaat velden
    serviceRapport?: ServiceRapportModel;
}

export enum KlantCommunicatieType {
    MAIL_SERVICE_RAPPORT = "MAIL_SERVICE_RAPPORT",
    MAIL_BEZOEK_NIET_GELUKT = "MAIL_BEZOEK_NIET_GELUKT"
}

export interface KlantCommunicatieEmailVerzendingModel {
    type: KlantCommunicatieType;
    emailAdres: string;
    tijdstip: string;
}

export interface KlantCommunicatieDefinitieModel extends OpvolgingItemDefinitieModel {
    emailVerzendingen: KlantCommunicatieEmailVerzendingModel[];
}

export interface AccessoireLeveringModel extends OpvolgingItemDefinitieModel {
    uitgevoerdDoor: MedewerkerModel;
    tijdstip: string;
    //prestatie: PrestatieModel & { opdracht: AccessoireLeveringOpdrachtModel };
    prestatieId: string;

    artikelen: {
        artikel: ArtikelModel;
        aantal: number;
        onvolledigeLeveringReden?: OnvolledigeAccessoireLeveringReden;
    }[];
}

export interface UpdatePlanningDefinitieModel extends OpvolgingItemDefinitieModel {
    bezoekAanvraagId: string;
}

export interface DynamicsOrderOpvolgingDefinitieModel extends OpvolgingItemDefinitieModel {
    gecontroleerdTijdstip: string;
    items: DynamicsOrderOpvolgingItemModel[];
}

export interface DynamicsOrderOpvolgingItemModel {
    platformRef: PlatformRefModel;
    dynamicsOrderRef?: DynamicsOrderRefModel;
    geboekt?: boolean;
}

export interface DynamicsServiceContractOpvolgingDefinitieModel extends OpvolgingItemDefinitieModel {
    items: DynamicsServiceContractOpvolgingItemModel[];
}

export interface DynamicsServiceContractOpvolgingItemModel {
    serviceContractRef: ServiceContractRefModel;
}

export interface ToestelServiceLogBoekAanvullenDefinitieModel extends OpvolgingItemDefinitieModel {
    operationeelToestelId: string;
    opvolgingItemId: string;
}

export interface TransportKostOpvolgingDefinitieModel extends OpvolgingItemDefinitieModel {
    gecontroleerdTijdstip: string;
    items: TransportKostOpvolgingItemModel[];

    teBoekenOrderPlatformRef: PlatformRefModel;

    transportKostType?: TransportKostConfiguratieType;
    transportKostValue: number;

    berekendeTransportKost: number;
}

export interface TransportKostOpvolgingItemModel {
    platformRef: PlatformRefModel;
    dynamicsOrderRef?: DynamicsOrderRefModel;

    toegepast: boolean;
    transportKost: number;

    transportKostOnderhevig: TransportKostOnderhevig;
    transportKostConfiguratieType: TransportKostConfiguratieType;
}

export interface TransportKostOpvolgingFormModel extends ItemOpvolgingFormModel {
    teBoekenOrder: PlatformRefParam;
    transportKostType: TransportKostConfiguratieType;
    transportKostValue?: number;
}

export enum PlatformRefType {
    INTERVENTIE = "INTERVENTIE",
    ONDERHOUD = "ONDERHOUD",
    ACCESSOIRE_BESTELLING = "ACCESSOIRE_BESTELLING",
    CO2_BESTELLING = "CO2_BESTELLING",
    INSTALLATIE = "INSTALLATIE"
}

export interface PlatformRefModel {
    type: PlatformRefType;
    id: string;
    nr: string;
    label: string;
}

export interface PlatformRefParam {
    type: PlatformRefType;
    id: string;
}

export enum DynamicsOrderRefType {
    SERVICE_ORDER = "SERVICE_ORDER",
    VERKOOP_ORDER = "VERKOOP_ORDER"
}

export interface DynamicsOrderRefModel {
    type: DynamicsOrderRefType;
    id: string;
    bedrijfId: string;
    nr: string;
    _links: _Links;
}

export enum OnvolledigeCo2LeveringReden {
    TE_WEINIG_FLESSEN_BESCHIKBAAR = "TE_WEINIG_FLESSEN_BESCHIKBAAR",
    ANDERE_SOORT_FLESSEN_GELEVERD = "ANDERE_SOORT_FLESSEN_GELEVERD",
    KLANT_AKKOORD = "KLANT_AKKOORD",
    VRAAG_KLANT = "VRAAG_KLANT",
    KLANT_KAN_TE_WEINIG_CO2_FLESSEN_OMRUILEN = "KLANT_KAN_TE_WEINIG_CO2_FLESSEN_OMRUILEN"
}

export enum OnvolledigeAccessoireLeveringReden {
    TE_WEINIG_ARTIKELEN_BESCHIKBAAR = "TE_WEINIG_ARTIKELEN_BESCHIKBAAR",
    ANDER_SOORT_ARTIKEL_GELEVERD = "ANDER_SOORT_ARTIKEL_GELEVERD",
    KLANT_AKKOORD = "KLANT_AKKOORD",
    VRAAG_KLANT = "VRAAG_KLANT"
}

export interface Co2LeveringModel extends OpvolgingItemDefinitieModel {
    medewerker: MedewerkerModel;
    tijdstip: string;
    artikel: ArtikelModel;
    aantal: number;
    aantalRetour: number;
    aantalGebruiksrechtVerloren: number;
    prestaties: (PrestatieModel & { opdracht: Co2LeveringOpdrachtModel })[];
    onvolledigeLeveringReden?: OnvolledigeCo2LeveringReden;

    // params
    externDocumentNr?: string;
    gebruiksrechtVerlorenBevestigd?: boolean;

    // resultaat
    toekenningen: Co2LeveringToekenningModel[];
    naleveringCo2Bestelling?: Co2BestellingModel;
}

export interface Co2LeveringToekenningModel {
    co2Bestelling: Co2BestellingModel;
    aantal: number;
    extraAantal: number;
}

export interface KlantOpmerkingModel extends OpvolgingItemDefinitieModel {
    opmerking: string;

    opgevolgd?: boolean;
}

export interface InterneFeedbackModel extends OpvolgingItemDefinitieModel {
    medewerker: MedewerkerModel;
    opmerking: string;

    feedbackOpgevolgd?: boolean;
}

export enum BijlageType {
    FOTO = "FOTO",
    COVER_FOTO = "COVER_FOTO",
    BESTAND = "BESTAND"
}

export enum InstallatieDefinitieResultaat {
    VOLLEDIG_AFGEWERKT = "VOLLEDIG_AFGEWERKT",
    NOG_NIET_OPERATIONEEL = "NOG_NIET_OPERATIONEEL",
    NIET_GEINSTALLEERD = "NIET_GEINSTALLEERD"
}

export enum InstallatieDefinitieNietAfgewerktReden {
    NOG_GEEN_WATER = "NOG_GEEN_WATER",
    NOG_GEEN_ELEKTRICITEIT = "NOG_GEEN_ELEKTRICITEIT",
    NOG_GEEN_WERKBLAD = "NOG_GEEN_WERKBLAD",
    NODIGE_UITPSARINGEN_IN_WERKBLAD_NIET_KLAAR = "NODIGE_UITPSARINGEN_IN_WERKBLAD_NIET_KLAAR",
    POMPBAK_NOG_NIET_GEINSTALLEERD = "POMPBAK_NOG_NIET_GEINSTALLEERD",
    AFVOER_NOG_NIET_AANGESLOTEN = "AFVOER_NOG_NIET_AANGESLOTEN",
    VERKEERDE_KRAAN_OF_TOESTEL_MEE = "VERKEERDE_KRAAN_OF_TOESTEL_MEE",
    TE_INSTALLEREN_TOESTEL_WERKT_NIET_WAARDOOR_RETOUR = "TE_INSTALLEREN_TOESTEL_WERKT_NIET_WAARDOOR_RETOUR",
    KRAAN_TE_HOOG = "KRAAN_TE_HOOG",
    TOESTEL_TE_GROOT = "TOESTEL_TE_GROOT",
    ANDERE = "ANDERE"
}

export enum InstallatieDefinitieNietGeinstalleerdReden {
    NOG_GEEN_WATER = "NOG_GEEN_WATER",
    NOG_GEEN_ELEKTRICITEIT = "NOG_GEEN_ELEKTRICITEIT",
    NOG_GEEN_WERKBLAD = "NOG_GEEN_WERKBLAD",
    NODIGE_UITPSARINGEN_IN_WERKBLAD_NIET_KLAAR = "NODIGE_UITPSARINGEN_IN_WERKBLAD_NIET_KLAAR",
    VERKEERDE_KRAAN_OF_TOESTEL_MEE = "VERKEERDE_KRAAN_OF_TOESTEL_MEE",
    TOESTEL_TE_GROOT = "TOESTEL_TE_GROOT",
    ANDERE = "ANDERE"
}

export interface InstallatieDefinitieModel extends OpvolgingItemDefinitieModel {
    uitgevoerdDoor: MedewerkerModel;
    tijdstip: string;

    geinstalleerdeComponenten: {
        artikel: ArtikelModel;
        aantal: number;
        serieNummer?: string;
    }[];
    verbruikteArtikelen: {
        artikel: ArtikelModel;
        aantal: number;
    }[];
    co2Artikelen: {
        artikel: ArtikelModel;
        aantal: number;
        aantalGeleverd: number;
    }[];

    resultaat: InstallatieDefinitieResultaat;
    nietAfgewerktReden?: InstallatieDefinitieNietAfgewerktReden;
    nietGeinstalleerdReden?: InstallatieDefinitieNietGeinstalleerdReden;

    fotos: { bestand: BestandModel }[];
    technicusTijden: { medewerker: MedewerkerModel; vanTijd: string; totTijd: string; }[];
    opmerking?: string;

    locatie?: string;
    gebouw?: string;
    verdieping?: string;
    lokaalNummer?: string;
    prestatieId: string;
    verkoopOrderId: string;

    // params
    nog_niet_operationeel_opgevolgd?: boolean;
    afwijkende_co2_opgevolgd?: boolean;

    // resultaat
    operationeelToestelId?: string;
    assemblageVerschiltVanComponenten?: boolean;
}

export enum ToestelInterventieControleWerkingResultaat {
    OKE = "OKE",
    OKE_OPVOLGING_VEREIST = "OKE_OPVOLGING_VEREIST",
    DEFECT = "DEFECT"
}

export interface ToestelInterventieDefinitieModel extends OpvolgingItemDefinitieModel {
    operationeelToestelId: string;
    uitgevoerdDoor: MedewerkerModel;

    technicusTijden: TechnicusVanTotTijdstip[];

    tijdstip: string;
    prestatieId: string;

    serviceOrderPlatformId: string;

    soortCode: string;

    soortLabel: string;

    verbruikteArtikelen: {
        artikel: ArtikelModel;
        aantal: number;
    }[];

    opmerking?: string;

    controleWerkingResultaat: ToestelInterventieControleWerkingResultaat;

    controleWerkingOpmerking?: string;

    opgevolgd?: boolean;
}

export enum ToestelOnderhoudControleWerkingResultaat {
    OKE = "OKE",
    OKE_OPVOLGING_VEREIST = "OKE_OPVOLGING_VEREIST",
    DEFECT = "DEFECT"
}

export interface ToestelOnderhoudDefinitieModel extends OpvolgingItemDefinitieModel {
    operationeelToestelId: string;
    // prestaties: PrestatieModel[];
    prestatieIds: string[];
    uitgevoerdDoor: MedewerkerModel;

    uitTeVoerenStappen: {
        reinigenEnSpoelen: boolean;
        uvVervangen: boolean;
        co2Vervangen: boolean;
        filtersVervangen: boolean;
        controleWerking: boolean;
        tappuntWisselen?: boolean;
    };

    uitgevoerdeStappen: {
        reinigenEnSpoelen: boolean;

        uvVervangen: boolean;

        co2Vervangen: boolean;

        filtersVervangen?: {
            artikel: ArtikelModel;
            lijnNr: number;
            nieuweFilterArtikel: ArtikelModel;
        }[];

        resultaat: ToestelOnderhoudControleWerkingResultaat;

        resultaatOpmerking?: string;

        tappuntWisselen?: boolean;
    }

    // opvolging
    actie?: OnderhoudOpvolgingActie;
    uitvoeringsDatumVanTot?: {
        van?: Date;
        tot?: Date;
    };
}

export interface ToestelBijlagenModel extends OpvolgingItemDefinitieModel {
    operationeelToestelId: string;

    items: {
        type: BijlageType;
        bestand: BestandModel;
        aangemaaktDoor: MedewerkerModel;
        creatieTijdstip: string;
    }[];
}

export interface ToestelInformatieAanpassingModel extends OpvolgingItemDefinitieModel {
    operationeelToestelId: string;

    serieNummer: string;
    serieNummerOrigineel: string;

    locatie: {
        gebouw: string;
        gebouwOrigineel: string;
        verdieping: string;
        verdiepingOrigineel: string;
        lokaal: string;
        lokaalOrigineel: string;
        omschrijving: string;
        omschrijvingOrigineel: string;
    };

    nieuweOpmerking?: string;
    toepassen?: boolean;
}

export interface ToestelStuklijstAanpassingModel extends OpvolgingItemDefinitieModel {
    operationeelToestelId: string;
    uitgevoerdDoor: MedewerkerModel;
    tijdstip: string;

    toegevoegdeStukken: { artikel: ArtikelModel }[];
    verwijderdeStukken: { artikel: ArtikelModel, lijnNr: number }[];
    verwisseldeStukken: { artikel: ArtikelModel, lijnNr: number; nieuwArtikel: ArtikelModel }[];

    toepassen?: boolean;
}

export enum NietUitgevoerdRedenType {
    NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN = "NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN",
    NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT = "NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT",
    PLANNINGSWIJZIGING_VANUIT_AQUALEX = "PLANNINGSWIJZIGING_VANUIT_AQUALEX",
    ANDERE_TECHNICUS_WERKT_VERDER = "ANDERE_TECHNICUS_WERKT_VERDER",
    ANDERE = "ANDERE",
}

export enum NietBezochtRedenType {
    ADRES_NIET_GEVONDEN = "ADRES_NIET_GEVONDEN",
    ADRES_ONBEREIKBAAR = "ADRES_ONBEREIKBAAR",
    ADRES_UITZONDERLIJK_GESLOTEN = "ADRES_UITZONDERLIJK_GESLOTEN",
    ADRES_GESLOTEN_OPENINGSUREN_INCORRECT = "ADRES_GESLOTEN_OPENINGSUREN_INCORRECT",
    ADRES_GESLOTEN_OPENINGSUREN_CORRECT_MAAR_BUITEN_OPENINGSUREN_AANGEKOMEN = "ADRES_GESLOTEN_OPENINGSUREN_CORRECT_MAAR_BUITEN_OPENINGSUREN_AANGEKOMEN",
    KLANT_VERHUISD = "KLANT_VERHUISD",
    NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN = "NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN",
    NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT = "NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT",
    PLANNINGSWIJZIGING_VANUIT_AQUALEX = "PLANNINGSWIJZIGING_VANUIT_AQUALEX",
    ANDERE_TECHNICUS_WERKT_VERDER = "ANDERE_TECHNICUS_WERKT_VERDER",
    ANDERE = "ANDERE"
}

export enum NietBezochtRedenTypeCategorie {
    ADRES_BELEMMERD = "ADRES_BELEMMERD",
    GESLOTEN_DOOR_SYSTEEM = "GESLOTEN_DOOR_SYSTEEM"
}

export interface NietUitgevoerdWerkDefinitieModel extends OpvolgingItemDefinitieModel {
    nietUitgevoerdReden: NietUitgevoerdRedenType;
    toelichting?: string;
    prestaties: PrestatieModel[];
    uitvoeringsDatumVanTot?: {
        van: Date;
        tot: Date;
    };
    prestatieNogUitvoeren: Map<string, boolean>;
}

export interface NietUitgevoerdWerkPlanModel {
    prestatieId: string;
    nogUitvoeren: boolean;
    uitvoeringsDatum: Date;
}

export enum KlantEmailAdresAanpassing {
    TOEVOEGEN = "TOEVOEGEN",
    VERWIJDEREN = "VERWIJDEREN"
}

export interface KlantEmailAdresAanpassingModel extends OpvolgingItemDefinitieModel {
    items: {
        emailAdres: string;
        actie: KlantEmailAdresAanpassing;
    }[];

    geenEmailAdres: boolean;
    verwerkt?: boolean;
}

export enum ItemOpvolgingFormType {
    INTERNE_FEEDBACK = "INTERNE_FEEDBACK",
    CO2_LEVERING = "CO2_LEVERING",
    KLANT_EMAIL_ADRES_AANPASSING = "KLANT_EMAIL_ADRES_AANPASSING",
    KLANT_OPMERKING = "KLANT_OPMERKING",
    TOESTEL_ONDERHOUD = "TOESTEL_ONDERHOUD",
    TOESTEL_INTERVENTIE = "TOESTEL_INTERVENTIE",
    NIET_UITGEVOERD_WERK = "NIET_UITGEVOERD_WERK",
    TOESTEL_STUKLIJST_AANPASSING = "TOESTEL_STUKLIJST_AANPASSING",
    TOESTEL_INFORMATIE_AANPASSING = "TOESTEL_INFORMATIE_AANPASSING",
    INSTALLATIE = "INSTALLATIE",
    DYNAMICS_ORDERS_OPVOLGING = "DYNAMICS_ORDERS_OPVOLGING",
    DYNAMICS_SERVICECONTRACT_OPVOLGING = "DYNAMICS_SERVICECONTRACT_OPVOLGING",
    TRANSPORT_KOST_OPVOLGING = "TRANSPORT_KOST_OPVOLGING"
}

export interface ItemOpvolgingFormModel {
    type: ItemOpvolgingFormType;
}

export interface KlantEmailAdresAanpassingOpvolgingFormModel extends ItemOpvolgingFormModel {
    // type: ItemOpvolgingFormType.KLANT_EMAIL_ADRES_WIJZIGING;
    emailAdressenVerwerktInDynamics?: boolean;
}

export interface InterneFeedbackOpvolgingFormModel extends ItemOpvolgingFormModel {
    // type: ItemOpvolgingFormType.INTERNE_FEEDBACK;
    feedbackOpgevolgd?: boolean;
}

export interface Co2LeveringOpvolgingFormModel extends ItemOpvolgingFormModel {
    // type: ItemOpvolgingFormType.CO2_LEVERING;
    externDocumentNr?: string | null;
    gebruiksrechtVerlorenBevestigd?: boolean | null;
}

export interface ToestelInformatieAanpassingOpvolgingFormModel extends ItemOpvolgingFormModel {
    toepassen?: boolean;
}

export interface ToestelStuklijstAanpassingOpvolgingFormModel extends ItemOpvolgingFormModel {
    toepassen?: boolean;
}

export interface ToestelOnderhoudOpvolgingFormModel extends ItemOpvolgingFormModel {
    // type: ItemOpvolgingFormType.TOESTEL_ONDERHOUD;
    actie?: OnderhoudOpvolgingActie;
    uitvoeringsDatumVanTot?: {
        van?: Date;
        tot?: Date;
    };
}

export enum OnderhoudOpvolgingActie {
    HERPLAN = "HERPLAN",
    ACCEPTEER = "ACCEPTEER",
}

export interface ToestelInterventieOpvolgingFormModel extends ItemOpvolgingFormModel {
    // type: ItemOpvolgingFormType.TOESTEL_INTERVENTIE;
    opgevolgd?: boolean;
}

export enum InstallatieNietGeinstalleerdOpvolgingActie {
    OPNIEUW_INPLANNEN = "OPNIEUW_INPLANNEN",
    NIET_OPNIEUW_INPLANNEN = "NIET_OPNIEUW_INPLANNEN",
    TOCH_GEINSTALLEERD = "TOCH_GEINSTALLEERD"
}

export enum InstallatieAssemblageNagekeken {
    JA_EN_PAS_TOE = "JA_EN_PAS_TOE",
    JA_EN_PAS_NIET_TOE = "JA_EN_PAS_NIET_TOE"
}

export interface ToestelInstallatieOpvolgingFormModel extends ItemOpvolgingFormModel {
    nogNietOperationeelOpgevolgd?: boolean;
    afwijkendeCo2Opgevolgd?: boolean;
    assemblageNagekeken?: InstallatieAssemblageNagekeken;

    nietGeinstalleerdOpvolgingActie?: InstallatieNietGeinstalleerdOpvolgingActie;
    nietGeinstalleerdOpnieuwInplannenVanTot?: {
        van?: Date;
        tot?: Date;
    };
}

export interface NietUitgevoerdWerkOpvolgingFormModel extends ItemOpvolgingFormModel {
    prestatieNogUitvoeren: Map<string, boolean>;
    uitvoeringsDatumVanTot?: {
        van?: Date;
        tot?: Date;
    };
}

export interface NietUitgevoerdWerkPlanEntry {
    prestatieId: string;
    nogUitvoeren: boolean;
}
