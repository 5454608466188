import * as React from "react";
import InstallatieVereiste from "./InstallatieVereiste";
import {SwitchFormField} from "../../../components/aqualex/form/SwitchFormField";

interface ToestellenProps {
    idPrefix: string;
    guard: boolean;
    label: string;
    waardePropertyName: string;
    waarde?: boolean;
    opmerkingPropertyName: string;
    opmerking?: string;
}

const BooleanInstallatieVereiste: React.FC<ToestellenProps> = ({
                                                                   idPrefix,
                                                                   guard,
                                                                   label,
                                                                   waardePropertyName,
                                                                   waarde,
                                                                   opmerkingPropertyName,
                                                                   opmerking
                                                               }) => {

    return (
        <InstallatieVereiste guard={guard}
                             waardePropertyName={waardePropertyName}
                             waarde={waarde}
                             label={label}
                             opmerkingPropertyName={opmerkingPropertyName}
                             opmerking={opmerking}
                             waardeRenderer={(waarde) => {
                                 return <SwitchFormField id={(idPrefix || "") + waardePropertyName} name={waardePropertyName} />
                             }}
        />
    )
};

export default BooleanInstallatieVereiste;
