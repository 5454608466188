// @flow
import "./apm";
import React from 'react';
import "./i18n";
import {Routes} from './routes/Routes';

// Themes
import './assets/scss/Saas.scss';
import Config from "./helpers/Config";
import {AuthProvider} from "./auth/authProvider";
import {useJsApiLoader} from "@react-google-maps/api";
import {RefDataLoader} from "./RefDataLoader";

// For Dark import DarkTheme.scss
// import './assets/scss/DarkTheme.scss';

// For green - import GreenTheme.scss
// import './assets/scss/GreenTheme.scss';

interface AppProps {
}

/**
 * Main app component
 */
const App: React.FC<AppProps> = () => {
    useJsApiLoader({
        googleMapsApiKey: Config.BACKOFFICE_GOOGLE_MAPS_KEY,
        libraries: Config.BACKOFFICE_GOOGLE_MAPS_LIBRARIES as any,
        language: "nl"
    });

    return (
        <>
            <AuthProvider>
                <RefDataLoader/>
                <Routes/>
            </AuthProvider>
        </>
    );
}

export default App;
