import {InterventieProgressTickTypes, ProgressTick} from "../interventie/types";

export interface GenereerOpdrachtenUitServiceOrderNrsForm {
    serviceOrderNrs: string[];
}

export interface GenereerOpdrachtenUitOnderhoudenForm {
    serviceOrderDatumVan: Date;
    serviceOrderDatumTot: Date;
    postcode: string;
}

export enum GenereerOpdrachtenProgressTickTypes {
    OpdrachtenGenererenOpdrachtAanmakenGefaaldTick = "OpdrachtenGenererenOpdrachtAanmakenGefaaldTick",
    OpdrachtenGenererenOpdrachtAanmakenGestartTick = "OpdrachtenGenererenOpdrachtAanmakenGestartTick",
    OpdrachtenGenererenOpdrachtAanmakenSuccesTick = "OpdrachtenGenererenOpdrachtAanmakenSuccesTick",
    OpdrachtenGenererenServiceOrderOpzoekenGefaaldTick = "OpdrachtenGenererenServiceOrderOpzoekenGefaaldTick",
    OpdrachtenGenererenServiceOrderOpzoekenGestartTick = "OpdrachtenGenererenServiceOrderOpzoekenGestartTick",
    OpdrachtenGenererenServiceOrderOpzoekenSuccesTick = "OpdrachtenGenererenServiceOrderOpzoekenSuccesTick",
    OpdrachtenGenererenServiceOrdersOpzoekenGestartTick = "OpdrachtenGenererenServiceOrdersOpzoekenGestartTick",
    OpdrachtenGenererenServiceOrdersOpzoekenSuccesTick = "OpdrachtenGenererenServiceOrdersOpzoekenSuccesTick",
    OpdrachtenGenererenSyncGestartTick = "OpdrachtenGenererenSyncGestartTick",
    OpdrachtenGenererenSyncSuccesTick = "OpdrachtenGenererenSyncSuccesTick",
}

export interface OpdrachtenGenererenOpdrachtAanmakenGestartTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventieAanmakenGestartTick;
    serviceOrderNr: string;
    serviceArtikelNr: string;
}

export interface OpdrachtenGenererenOpdrachtAanmakenSuccesTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventieAanmakenSuccesTick;
    opdrachtId: string;
    serviceOrderNr: string;
    serviceArtikelNr: string;
}

export interface OpdrachtenGenererenOpdrachtAanmakenGefaaldTick extends ProgressTick {
    type: InterventieProgressTickTypes.InterventieAanmakenGefaaldTick;
    serviceOrderNr: string;
    serviceArtikelNr: string;
    foutCode: string;
    exceptionMessage: string;
}

export interface OpdrachtenGenererenServiceOrdersOpzoekenGestartTick extends ProgressTick {
    type: GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrdersOpzoekenGestartTick;
}

export interface OpdrachtenGenererenServiceOrdersOpzoekenSuccesTick extends ProgressTick {
    type: GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrdersOpzoekenSuccesTick;
}

export interface OpdrachtenGenererenServiceOrderOpzoekenGestartTick extends ProgressTick {
    type: GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrderOpzoekenGestartTick;
    serviceOrderNr: string;
}

export interface OpdrachtenGenererenServiceOrderOpzoekenSuccesTick extends ProgressTick {
    type: GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrderOpzoekenSuccesTick;
    serviceOrderNr: string;
}

export interface OpdrachtenGenererenServiceOrderOpzoekenGefaaldTick extends ProgressTick {
    type: GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenServiceOrderOpzoekenGefaaldTick;
    serviceOrderNr: string;
    foutCode: string;
    exceptionMessage: string;
}

export interface OpdrachtenGenererenSyncGestartTick extends ProgressTick {
    type: GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenSyncGestartTick;
    serviceOrderNr: string;
}

export interface OpdrachtenGenererenSyncSuccesTick extends ProgressTick {
    type: GenereerOpdrachtenProgressTickTypes.OpdrachtenGenererenSyncSuccesTick;
    serviceOrderNr: string;
}
