import React, {useEffect} from "react";
import {fetchAnnulatieRedenenRequest} from "./redux/refdata/actions";
import {useDispatch} from "react-redux";
import {useGetMedewerkersSummary} from "./redux/medewerker/api";

export const RefDataLoader: React.FC = () => {
    const dispatch = useDispatch();

    useGetMedewerkersSummary({});

    useEffect(() => {
        dispatch(fetchAnnulatieRedenenRequest());
    }, [dispatch]);

    return null;
};
