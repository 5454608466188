import React, {ReactNode, useMemo} from "react";
import {Col, ListGroup, ListGroupItem, ProgressBar, Row} from "react-bootstrap";
import moment from "moment/moment";
import {
    EstimatedTimeRemainingTick, ExcelAangemaaktProgressTick,
    GenericProgressTickTypes,
    ProgressFinishedTick,
    ProgressTick
} from "../../redux/interventie/types";
import { useTranslation } from "../../helpers/i18nUtils";
import { Countdown } from "./Countdown";

export interface BulkActieItemRenderer {
    canRender(tick: any): boolean;

    render(tick: any): {
        key: string;
        icon: ReactNode;
        title: ReactNode;
        description: ReactNode;
        isError?: boolean;
        sticky?: boolean;
    };
}

export interface BulkActieLijstProps {
    ticks: ProgressTick[];
    renderers: BulkActieItemRenderer[];
}

export const BulkActieLijst: React.FC<BulkActieLijstProps> = (props) => {
    const {ticks, renderers} = props;

    const mappedTicks = useMemo(() => {
        const groupedEvents: any = {};

        for (const tick of ticks) {
            const renderer = renderers.find(renderer => renderer.canRender(tick));

            if (renderer) {
                const verwerkteEvent = renderer.render(tick);

                groupedEvents[verwerkteEvent.key] = verwerkteEvent;
            }
        }

        const verwerkteEvents = Object.values(groupedEvents).reverse() as any[];

        const stickyItems = verwerkteEvents.filter(item => !item.isError && item.sticky);
        const fouten = verwerkteEvents.filter(item => item.isError);
        const succesvolleItems = verwerkteEvents.filter(item => !item.isError && !item.sticky);

        return [...stickyItems, ...fouten, ...succesvolleItems];
    }, [ticks, renderers]);

    const {t} = useTranslation("algemeen");

    const omgekeerdeTicks = ticks.concat().reverse();
    const estimatedTimeRemainingEvent = omgekeerdeTicks.find(item => item.type === GenericProgressTickTypes.EstimatedTimeRemainingTick) as EstimatedTimeRemainingTick;
    const progressFinishedTick = omgekeerdeTicks.find(item => item.type === GenericProgressTickTypes.ProgressFinishedTick) as ProgressFinishedTick;
    const excelAangemaaktProgressTick = omgekeerdeTicks.find(item => item.type === GenericProgressTickTypes.ExcelAangemaaktProgressTick) as ExcelAangemaaktProgressTick;

    const geschatEindTijdstip = moment().add(estimatedTimeRemainingEvent?.resterendeTijdMs || 0, "ms");
    const done = progressFinishedTick !== undefined;

    const showProgressBar = estimatedTimeRemainingEvent || progressFinishedTick;

    return (
        <>
            {showProgressBar && (
                <ProgressBar style={{minHeight: "1.8em"}} animated={!done}
                         now={done ? 1 : estimatedTimeRemainingEvent?.huidigAantalTicks} min={0}
                         max={done ? 1 : estimatedTimeRemainingEvent?.geschatAantalTicks}
                         label={<Countdown targetDate={geschatEindTijdstip.toDate()}/>}/>
            )}

            <Row className="mt-2 mb-2 flex-row justify-content-between align-items-center">
                {done && <Col><span
                    className="text-muted">{t("Labels.mail-verzonden-met-rapport", "Een e-mail met het rapport werd verzonden.")}</span></Col>}

                {excelAangemaaktProgressTick && (
                    <Col xs="auto"><a target="_blank" href={excelAangemaaktProgressTick.bestandUrl}
                                      className="btn btn-light">{t("Labels.rapport-downloaden", "Rapport downloaden")} <i className="mdi mdi-download"/></a></Col>
                )}
            </Row>

            <ListGroup style={{maxHeight: "80vh", overflowY: "auto"}} className="mt-2">
                {mappedTicks.map((tick: any) => (
                    <ListGroupItem key={tick.key} className="d-flex align-items-center">
                        <Row>
                            <Col xs="auto">
                                {tick.icon}
                            </Col>
                            <Col xs="auto">
                                <strong>{tick.title}</strong>
                            </Col>
                            <Col>
                                {tick.description}
                            </Col>
                        </Row>
                    </ListGroupItem>
                ))}
            </ListGroup>
        </>
    );
};
