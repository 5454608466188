import {Spinner} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiAlertCircle, mdiCheckCircle, mdiLinkBox, mdiOpenInNew} from "@mdi/js";
import {Translation} from "react-i18next";
import React, {ReactNode} from "react";
import {NavLink} from "react-router-dom";
import {
    InterventieAanmakenGefaaldTick,
    InterventieAanmakenGestartTick,
    InterventieAanmakenSuccesTick,
    InterventiePlannenGefaaldTick,
    InterventiePlannenGestartTick,
    InterventiePlannenSuccesTick,
    InterventieProgressTickTypes,
    ProgressTick
} from "../../../redux/interventie/types";
import {BulkActieItemRenderer} from "../../../components/aqualex/BulkActieLijst";

export const interventieBulkItemRenderers: BulkActieItemRenderer[] = [
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieProgressTickTypes.InterventieAanmakenGestartTick;
        },
        render(tick: InterventieAanmakenGestartTick): any {
            return {
                key: tick.serviceArtikelNr,
                icon: <Spinner animation="border" size="sm"/>,
                title: tick.serviceArtikelNr,
                description: <Translation ns="interventie">{t => t("Labels.interventie-aanmaken", "Interventie aanmaken...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieProgressTickTypes.InterventieAanmakenSuccesTick;
        },
        render(tick: InterventieAanmakenSuccesTick): any {
            return {
                key: tick.serviceArtikelNr,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: tick.serviceArtikelNr,
                description: <Translation ns="interventie">{t => <div>{t("Labels.interventie-aangemaakt", "Interventie aangemaakt")} <NavLink target="_blank"
                                                                                                      to={`/interventie/${tick.interventieId}`}><Icon size={1 / 1.2} path={mdiOpenInNew}/></NavLink></div>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieProgressTickTypes.InterventieAanmakenGefaaldTick;
        },
        render(tick: InterventieAanmakenGefaaldTick): any {
            return {
                key: tick.serviceArtikelNr,
                icon: <Icon size={1 / 1.5} path={mdiAlertCircle} className="text-danger"/>,
                title: tick.serviceArtikelNr,
                description: <Translation ns="interventie">{t => <span>{t("Labels.interventie-aanmaken-mislukt", "Interventie aanmaken mislukt")}: {t(tick.foutCode)} {tick.exceptionMessage}</span>}</Translation>,
                isError: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieProgressTickTypes.InterventiePlannenGestartTick;
        },
        render(tick: InterventiePlannenGestartTick): any {
            return {
                key: tick.serviceArtikelNr,
                icon: <Spinner animation="border" size="sm"/>,
                title: tick.serviceArtikelNr,
                description: <Translation ns="interventie">{t => t("Labels.interventie-plannen", "Interventie plannen...")}</Translation>,
                isError: false,
                sticky: true
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieProgressTickTypes.InterventiePlannenSuccesTick;
        },
        render(tick: InterventiePlannenSuccesTick): any {
            return {
                key: tick.serviceArtikelNr,
                icon: <Icon size={1 / 1.5} path={mdiCheckCircle} className="text-success"/>,
                title: tick.serviceArtikelNr,
                description: <Translation ns="interventie">{t => <div>{t("Labels.interventie-planning-voorbereid", "Interventie planning voorbereid")} <NavLink target="_blank"
                                                                                                                                              to={`/interventie/${tick.interventieId}`}><Icon size={1 / 1.2} path={mdiOpenInNew}/></NavLink></div>}</Translation>,
                isError: false
            };
        }
    },
    {
        canRender(tick: ProgressTick): boolean {
            return tick.type === InterventieProgressTickTypes.InterventiePlannenGefaaldTick;
        },
        render(tick: InterventiePlannenGefaaldTick): any {
            return {
                key: tick.serviceArtikelNr,
                icon: <Icon size={1 / 1.5} path={mdiAlertCircle} className="text-danger"/>,
                title: tick.serviceArtikelNr,
                description: <Translation ns="interventie">{t => <span>{t("Labels.planning-voorbereiden-van-interventie-mislukt", "Planning voorbereiden van interventie mislukt")}: {t(tick.foutCode)} {tick.exceptionMessage}</span>}</Translation>,
                isError: true
            };
        }
    }
];
